<div class="max-content-width">
  <div class="container container-thin">
    <div class="top-gap"></div>
    <h1 class="title">Terms of Service</h1>
    <div class="section">
      <h2 class="title-2">1. Introduction</h2>
      <p class="desc">
        Welcome to FusionFlow! These Terms of Service ("Terms") govern your use
        of our services, websites, and applications ("Services"). By accessing
        or using FusionFlow's Services, you agree to these Terms.
      </p>
    </div>

    <div class="section">
      <h2 class="title-2">2. Acceptance of Terms</h2>
      <p class="desc">
        By creating an account or using the Services, you accept and agree to be
        bound by these Terms and our Privacy Policy.
      </p>
    </div>

    <div class="section">
      <h2 class="title-2">3. Description of Services</h2>
      <p class="desc">
        FusionFlow provides a suite of online productivity tools and
        applications to help individuals and businesses manage their operations
        effectively.
      </p>
    </div>

    <div class="section">
      <h2 class="title-2">4. User Responsibilities</h2>
      <ul class="list-unstyled">
        <li>Provide accurate and complete information during registration.</li>
        <li>Ensure the confidentiality of your account credentials.</li>
        <li>
          Use the Services only for lawful purposes and in compliance with
          applicable laws.
        </li>
      </ul>
    </div>

    <div class="section">
      <h2 class="title-2">5. Subscription and Payment</h2>
      <p class="desc">
        Certain Services may require a paid subscription. Payment terms, billing
        cycles, and refund policies are described at the time of purchase.
      </p>
    </div>

    <div class="section">
      <h2 class="title-2">6. Intellectual Property</h2>
      <p class="desc">
        All content, trademarks, and intellectual property associated with
        FusionFlow are owned by FusionFlow Corporation and protected by
        applicable laws.
      </p>
    </div>

    <div class="section">
      <h2 class="title-2">7. Termination of Service</h2>
      <p class="desc">
        FusionFlow reserves the right to suspend or terminate your account if
        you violate these Terms or engage in activities that harm FusionFlow's
        Services or users.
      </p>
    </div>

    <div class="section">
      <h2 class="title-2">8. Limitation of Liability</h2>
      <p class="desc">
        FusionFlow is not responsible for any indirect, incidental, or
        consequential damages arising from the use of its Services.
      </p>
    </div>

    <div class="section">
      <h2 class="title-2">9. Privacy Policy</h2>
      <p class="desc">
        Your use of FusionFlow Services is subject to our Privacy Policy, which
        explains how we collect, use, and safeguard your personal information.
      </p>
    </div>

    <div class="section">
      <h2 class="title-2">10. Amendments to Terms</h2>
      <p class="desc">
        FusionFlow may update these Terms periodically. Continued use of the
        Services after changes indicates your acceptance of the revised Terms.
      </p>
    </div>

    <div class="section">
      <h2 class="title-2">11. Governing Law</h2>
      <p class="desc">
        These Terms are governed by the laws of [applicable jurisdiction].
        Disputes will be resolved in the courts of [jurisdiction].
      </p>
    </div>

    <div class="section">
      <h2 class="title-2">12. Contact Us</h2>
      <p class="desc">
        If you have questions about these Terms, contact us at
        <a href="mailto:support@FusionFlow.com">support@FusionFlow.com</a>.
      </p>
    </div>
  </div>
</div>
