<div class="welcome-user d-flex align-items-center justify-content-between">
  <div>
    <h4 class="mb-0">FusionFlow Help For Form Instnace</h4>
    <p class="mb-0">Dashboard / Help</p>
  </div>
  <div class="d-flex align-items-center flex-gap">
    <div class="position-relative">
      <button class="btn btn-danger-gradient" type="button">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
          >
            <path
              d="M6.31616 0.5C3.00785 0.5 0.316162 3.19169 0.316162 6.5C0.316162 7.50308 0.572162 8.49385 1.0577 9.37877L0.334008 11.9117C0.287854 12.0729 0.332778 12.2465 0.451547 12.3646C0.539547 12.4526 0.657085 12.5 0.777701 12.5C0.820162 12.5 0.862624 12.4945 0.90447 12.4822L3.43739 11.7585C4.32232 12.2446 5.31309 12.5 6.31616 12.5C9.62447 12.5 12.3162 9.80831 12.3162 6.5C12.3162 3.19169 9.62447 0.5 6.31616 0.5ZM6.70385 8.98C6.6177 9.06615 6.49462 9.11539 6.3777 9.11539C6.31616 9.11539 6.25462 9.10308 6.19924 9.07846C6.14386 9.05385 6.09462 9.02308 6.05155 8.98C6.00847 8.93692 5.97155 8.88769 5.94693 8.83231C5.92847 8.77692 5.91616 8.71538 5.91616 8.65385C5.91616 8.59231 5.92847 8.53077 5.94693 8.47538C5.97155 8.42 6.00847 8.37077 6.05155 8.32769C6.09462 8.28462 6.14386 8.25385 6.19924 8.22923C6.31001 8.18 6.43924 8.18 6.55001 8.22923C6.61155 8.25385 6.66078 8.28462 6.70385 8.32769C6.74693 8.37077 6.7777 8.42 6.80232 8.47538C6.82693 8.53077 6.83924 8.59231 6.83924 8.65385C6.83924 8.77692 6.79001 8.89385 6.70385 8.98ZM7.70385 6.25569C7.68785 6.27662 7.67001 6.29569 7.65155 6.31354L6.9297 6.98185C6.86939 7.07108 6.83801 7.17138 6.83801 7.27538C6.83801 7.53015 6.63124 7.73692 6.37647 7.73692C6.1217 7.73692 5.91493 7.53015 5.91493 7.27538C5.91493 6.95662 6.01893 6.65138 6.21647 6.39354C6.23247 6.37262 6.25032 6.35354 6.26939 6.33569L6.99124 5.66739C7.08847 5.52462 7.11185 5.35231 7.05647 5.18985C6.99616 5.01569 6.85401 4.88708 6.66632 4.83846C6.48047 4.78923 6.33401 4.80031 6.21832 4.86923C6.02878 4.98308 5.9297 5.24585 5.90816 5.33754C5.84847 5.58369 5.6017 5.74062 5.35493 5.68154C5.10816 5.62492 4.95308 5.38185 5.00785 5.13446C5.02385 5.06246 5.17893 4.42308 5.73401 4.084C5.96478 3.94308 6.35555 3.804 6.90016 3.94554C7.3857 4.07231 7.77032 4.42492 7.93032 4.89015C8.0897 5.35354 8.00539 5.86431 7.70509 6.25631L7.70385 6.25569Z"
              fill="white"
            />
          </svg>
        </span>
        Help
      </button>
    </div>
  </div>
</div>

<div class="help-content">
  <!-- getting flow card start here... -->
  <div class="card card-highlights mb-5">
    <div class="card-body">
      <h4 class="card-title">Getting started with FusionFlow</h4>
      <p class="card-desc">
        Welcome to FusionFlow! This guide will walk you through everything you
        need to know about using FusionFlow, from setting up your first project
        to equipping your team with all of the tools they need to get the job
        done. Each chapter includes easy to follow steps, tips, and templates
        that will turn you into a FusionFlow champion in no time.
      </p>
    </div>
    <span class="card-position-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="66"
        height="103"
        viewBox="0 0 66 103"
        fill="none"
      >
        <path
          d="M32.7369 6.71588C33.1073 4.3583 35.2404 4.3583 35.6108 6.71588L38.6356 25.9685C40.2166 36.0321 45.2173 43.8895 51.6219 46.3738L63.875 51.1267C65.375 51.7086 65.375 55.0603 63.875 55.6423L51.6219 60.3951C45.2172 62.8795 40.2166 70.737 38.6356 80.8004L35.6108 100.054C35.2404 102.411 33.1073 102.411 32.7369 100.054L29.7121 80.8004C28.131 70.7369 23.1304 62.8795 16.7258 60.3951L4.473 55.6423C2.97259 55.0603 2.97259 51.7086 4.473 51.1267L16.7258 46.3738C23.1304 43.8895 28.131 36.032 29.7121 25.9685L32.7369 6.71588ZM9.77436 2.14293C9.95959 0.964077 11.0261 0.964077 11.2114 2.14293L11.4445 3.62678C12.3009 9.07776 15.0096 13.3339 18.4787 14.6796L19.423 15.0458C20.1733 15.3369 20.1733 17.0127 19.423 17.3038L18.4787 17.6701C15.0096 19.0158 12.3009 23.2719 11.4445 28.7228L11.2114 30.2067C11.0261 31.3855 9.95959 31.3855 9.77436 30.2067L9.54125 28.7228C8.68481 23.2719 5.97615 19.0158 2.50704 17.6701L1.56268 17.3038C0.812439 17.0127 0.812439 15.3369 1.56268 15.0458L2.50704 14.6796C5.97615 13.3339 8.68481 9.07776 9.54125 3.62678L9.77436 2.14293Z"
          stroke="white"
          stroke-opacity="0.4"
          stroke-width="1.5"
        />
      </svg>
    </span>
  </div>
  <!-- getting flow card end here... -->

  <!-- suggestion start here... -->
  <h5 class="title-sm">Suggestions</h5>
  <div class="d-flex flex-wrap column-gap-3 row-gap-4 suggestion mb-5">
    <button class="btn btn-light-primary justify-content-center active" type="button">
      <span class="icon">
        <svg
          width="11"
          height="14"
          viewBox="0 0 21 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.847229 3.18173V24.8659H17.699V21.7682H20.6728V0.0839844H3.82106V3.18173H0.847229ZM16.7077 23.8334H1.83851V4.21431H13.2382V7.82835H16.7077V23.8334ZM14.2295 4.94434L16.0068 6.79576H14.2295V4.94434ZM4.81234 1.11657H19.6815V20.7356H17.699V7.09831L13.939 3.18173H4.81234V1.11657Z"
            fill="#781DEE"
          ></path>
          <path
            d="M3.82074 9.89364H14.7248V10.9262H3.82074V9.89364ZM3.82074 12.9914H14.7248V14.024H3.82074V12.9914ZM3.82074 16.0891H14.7248V17.1217H3.82074V16.0891ZM3.82074 20.2195H8.28149V21.252H3.82074V20.2195ZM10.264 20.2195H14.7248V21.252H10.264V20.2195ZM7.29021 6.7959H11.2553V7.82848H7.29021V6.7959Z"
            fill="#781DEE"
          ></path>
        </svg>
      </span>
      Employee Onboarding Workflow Setup Guide
    </button>
    <button class="btn btn-light-primary justify-content-center" type="button">
      <span class="icon">
        <svg
          width="11"
          height="14"
          viewBox="0 0 21 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.847229 3.18173V24.8659H17.699V21.7682H20.6728V0.0839844H3.82106V3.18173H0.847229ZM16.7077 23.8334H1.83851V4.21431H13.2382V7.82835H16.7077V23.8334ZM14.2295 4.94434L16.0068 6.79576H14.2295V4.94434ZM4.81234 1.11657H19.6815V20.7356H17.699V7.09831L13.939 3.18173H4.81234V1.11657Z"
            fill="#781DEE"
          ></path>
          <path
            d="M3.82074 9.89364H14.7248V10.9262H3.82074V9.89364ZM3.82074 12.9914H14.7248V14.024H3.82074V12.9914ZM3.82074 16.0891H14.7248V17.1217H3.82074V16.0891ZM3.82074 20.2195H8.28149V21.252H3.82074V20.2195ZM10.264 20.2195H14.7248V21.252H10.264V20.2195ZM7.29021 6.7959H11.2553V7.82848H7.29021V6.7959Z"
            fill="#781DEE"
          ></path>
        </svg>
      </span>
      Workflow instance basics
    </button>
    <button class="btn btn-light-primary justify-content-center" type="button">
      <span class="icon">
        <svg
          width="11"
          height="14"
          viewBox="0 0 21 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.847229 3.18173V24.8659H17.699V21.7682H20.6728V0.0839844H3.82106V3.18173H0.847229ZM16.7077 23.8334H1.83851V4.21431H13.2382V7.82835H16.7077V23.8334ZM14.2295 4.94434L16.0068 6.79576H14.2295V4.94434ZM4.81234 1.11657H19.6815V20.7356H17.699V7.09831L13.939 3.18173H4.81234V1.11657Z"
            fill="#781DEE"
          ></path>
          <path
            d="M3.82074 9.89364H14.7248V10.9262H3.82074V9.89364ZM3.82074 12.9914H14.7248V14.024H3.82074V12.9914ZM3.82074 16.0891H14.7248V17.1217H3.82074V16.0891ZM3.82074 20.2195H8.28149V21.252H3.82074V20.2195ZM10.264 20.2195H14.7248V21.252H10.264V20.2195ZM7.29021 6.7959H11.2553V7.82848H7.29021V6.7959Z"
            fill="#781DEE"
          ></path>
        </svg>
      </span>
      Ho to assign form instance
    </button>
  </div>
  <!-- suggestion end here... -->

  <!-- help content start here... -->
  <div class="suggestion-help-content pt-4">
    <h2 class="title">Employee Onboarding Workflow Setup Guide</h2>
    <p class="desc">
      This guide walks through creating an automated onboarding workflow using
      the iPaaS system, with each step explained to help you replicate
      <br />
      the provided onboarding template.
    </p>

    <!-- suggestion how to work content start here... -->
    <div class="how-to-work">
      <div class="step">
        <button
          class="btn btn-light-primary justify-content-center active fw-bold px-5 py-3 mb-4"
          type="button"
        >
          Step 1: Initial Setup for Employee Details
        </button>
        <div class="content">
          <h2 class="title">
            Trigger:
            <span>Create Instance </span>
          </h2>
          <p class="desc">
            Action 1:Drag the Create action from the Actions panel and configure
            it to create an instance of the Employee Details Form.
          </p>
          <p class="desc">
            Action 2: Use the Task action to assign this task to the Hiring
            Manager. Label it as "Get New Employee Details".
          </p>
          <p class="desc">
            Action 3: Use the SendEmail action to configure a notification and
            email template, sending details to the Hiring Manager.
          </p>
          <p class="desc">
            Action 4: Use the Complete action to mark this step as finished.
          </p>
        </div>
        <div class="previews"></div>
      </div>
      <div class="step">
        <button
          class="btn btn-light-primary justify-content-center active fw-bold px-5 py-3 mb-4"
          type="button"
        >
          Step 2: Department-Based Form Creation
        </button>
        <div class="content">
          <h2 class="title">
            Trigger:
            <span>Submit Instance</span>
          </h2>

          <p class="desc">
            Action 1: Drag the Submit action to submit the Employee Details Form
            instance.
          </p>
          <p class="desc">
            Action 2: Use the SetValue action to save variables like department,
            name, and email.
          </p>
          <p class="desc">
            Action 3: Configure another SendEmail action to send a welcome email
            to the new employee.
          </p>
          <p class="desc">
            Actions 4 & 5 (HR Department): Use Create to set up an instance of
            the HR Details Form. Add a Condition to only execute this if the
            department is "HR". Assign a Task to the HR Manager to provide HR
            software access to the new employee.
          </p>
          <p class="desc">
            Actions 6 & 7 (Marketing Department): Repeat the above steps for the
            Marketing Details Form, setting the condition to execute only if the
            department is "Marketing". Assign a Task to the Marketing Manager to
            provide marketing software access.
          </p>
          <p class="desc">Action 8: Use Complete to finalize this step.</p>
        </div>
      </div>
    </div>
    <!-- suggestion how to work content end here... -->
  </div>
  <!-- help content end here... -->
</div>
