import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldRejection, ErrorMessage } from 'src/app/main/model/api.model';
import { CommonController } from 'src/app/main/util/common.controller';
import { MyUserService, myGroupService } from 'src/app/user/service/user.service';
import { WorkflowTemplateService } from '../../service/workflow-template.service';
import { UserTaskService } from 'src/app/main/services/account.service';
import { OffcanvasRef } from 'src/app/ui/service/offcanvas-ref';
import { OFFCANVAS_DATA } from 'src/app/ui/service/offcanvas-token';
import { UserAccount } from 'src/app/main/model/account.model';
import { Projectservice, ProjectStatusService, ProjectUsersService } from 'src/app/main/services/project.service';
import { ICreateProject, ICreateProjectStatus } from 'src/app/main/model/project.model';
import { JwtService } from 'src/app/main/services/jwt.service';
import { FileUploadService } from 'src/app/main/services/fileUpload.service';

@Component({
  selector: 'app-workflow-task-assign',
  templateUrl: './workflow-task-assign.component.html',
  styleUrls: ['./_workflow-task-assign.component.scss'],
})
export class WorkflowTaskAssignComponent extends CommonController implements OnInit {
  @ViewChild('targetDiv') targetDiv: ElementRef;
  form: UntypedFormGroup;
  fieldRejections: FieldRejection[] = [];

  users: any = []
  groups: any = []
  assignmentType: string = 'User'
  assignedTo: any[] = []
  instanceId: string = ''
  workflowTemplateId: string = ''

  assignedTask: any = []
  taskData: any;
  editInput: boolean;
  statuses: ICreateProjectStatus[] = [];

  allPriority = [
    { name: 'low', colorClass: 'bg-gray-25' },
    { name: 'medium', colorClass: 'bg-orange-25' },
    { name: 'high', colorClass: 'bg-blue-25' },
  ]
  selectedStatus: string = '';
  attachments: string[] = []
  reporterId: string;
  showStartDatePicker: boolean;
  showEndDatePicker: boolean;
  priority: string;
  taskId: string;
  comments: string;
  showHistory: boolean = true;
  showDocuments: boolean
  showComment: boolean;
  allCommments: { rows: { comment: string, account: UserAccount, id: string }[], count: number } = { rows: [], count: 0 };
  editCommentId: string | null;
  selectedProject: ICreateProject;
  activatedAccount: any
  taskPreviousObject: any
  constructor(
    public myUserService: MyUserService,
    public projectUsersService: ProjectUsersService,
    public myGroupService: myGroupService,
    public formBuilder: UntypedFormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    public workflowTemplateService: WorkflowTemplateService,
    public userTaskService: UserTaskService,
    private offcanvasRef: OffcanvasRef,
    private projectService: Projectservice,
    private jwtService: JwtService,
    private projectStatusService: ProjectStatusService,
    private fileUploadService: FileUploadService,
    @Inject(OFFCANVAS_DATA) public data: any
  ) {
    super()
    this.form = this.formBuilder.group({
      taskName: [null, [Validators.required]],
      taskForm: [null, [Validators.required]],
      reporterId: [null, [Validators.required]],
      status: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      dueDate: [null, [Validators.required]],
      priority: [null, [Validators.required]],
      assignedTo: [null, [Validators.required]],
    });
  }

  selectedDate: Date | null = null;
  startDate: Date | null = new Date();
  @ViewChild('dateInput1') dateInput1!: ElementRef;
  @ViewChild('dateInput2') dateInput2!: ElementRef;
  openDatePicker(type): void {

    if (type === 'start') {
      this.showStartDatePicker = true;
      setTimeout(() => {
        const flatpickrInstance = this.dateInput1.nativeElement._flatpickr;
        if (flatpickrInstance) {
          flatpickrInstance.open();
        }
      }, 0);
    } else {
      this.showEndDatePicker = true;
      setTimeout(() => {
        const flatpickrInstance = this.dateInput2.nativeElement._flatpickr;
        if (flatpickrInstance) {
          flatpickrInstance.open();
        }
      }, 0);
    }

  }

  getProjectStatuses() {
    this.projectStatusService.projectStatus$.subscribe(statuses => {
      this.statuses = statuses;
    });
  }

  setTaskId(id) {
    this.taskId = id;
    this.fetchTask()
    this.targetDiv.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  fetchTask() {
    this.userTaskService.get(this.taskId).subscribe((response: any) => {

      this.taskData = response
      this.reporterId = response.reporterId;
      // Set other properties like selectedStatus, selectedDate, priority, etc.
      this.selectedStatus = response.status;
      this.selectedDate = response.dueDate ? new Date(response.dueDate) : null;
      this.startDate = response.startDate ? new Date(response.startDate) : null;
      this.priority = response.priority;
      const assignedToIds = response.assignedTo.split(',');
      this.attachments = Array.isArray(response.attachments) ? response.attachments : JSON.parse(response.attachments)
      this.assignedTo = assignedToIds.map(id => {
        const user = this.users.find(user => user.id === id);
        return user ? { id: user.id, name: user.name, email: user.email } : null;
      }).filter(user => user !== null);
      this.form.patchValue({
        taskName: response.taskName,
        taskForm: response.taskForm,
        reporterId: response.reporterId,
        status: response.status,
        priority: response.priority,
        assignedTo: this.assignedTo,
        dueDate: this.selectedDate,
        startDate: this.startDate,
      });
      this.taskPreviousObject = {
        taskName: response.taskName,
        taskForm: response.taskForm,
        reporterId: response.reporterId,
        status: response.status,
        priority: response.priority,
        assignedTo: this.assignedTo.map(data => data.id).join(","),
        dueDate: this.selectedDate,
        startDate: this.startDate,
      }
    });
  }

  onDateChange(date: any, type): void {
    if (type === 'start') {
      this.startDate = date.target.value;
      this.setFormValue("startDate", this.startDate)
      this.showStartDatePicker = false;;
    } else {
      this.selectedDate = date.target.value;
      this.setFormValue("dueDate", this.selectedDate)
      this.showEndDatePicker = false
    }
  }

  ngOnInit(): void {
    this.monitorJwtData()
    this.loadProjects()
    this.getProjectStatuses()
    // this.myGroupService.get('').subscribe({
    //   next: (data) => {
    //     this.groups = data.rows
    //   }
    // })
    // if (this.route.snapshot.paramMap.get("id")) {
    //   this.workflowTemplateId = this.route.snapshot.paramMap.get("id") || '';
    //   this.handleGetWorkflowTemplateData(this.workflowTemplateId);
    // }
  }

  private monitorJwtData(): void {
    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        if (data) {
          this.activatedAccount = data.account
        }
      },
    });
  }
  // handleGetWorkflowTemplateData(id) {
  //   this.workflowTemplateService.get(id).subscribe({
  //     next: (data: any) => {
  //       this.workFlowData = data;
  //       if (data && data.taskList) {
  //         this.taskData = (data.taskList == ' ' || data.taskList == '') ? [] : JSON.parse(data.taskList)
  //       }
  //     },
  //   });
  // }


  private loadProjects(): void {

    this.projectService.getProjects().subscribe(
      (projects: ICreateProject[]) => {
        this.selectedProject = projects.find(item => item.id === this.projectService.getSelectedProject(this.activatedAccount.id)) ?? projects[0];
        if (this.selectedProject) {
          this.projectUsersService.list({ limit: 100, filter: { projectId: this.selectedProject?.id } }).subscribe({
            next: (data) => {
              this.users = data.rows.map(item => item.account);
              if (this.data?.id) {
                this.taskId = this.data.id;
                if (this.data.isSubTask === false) {
                  this.fetchTask();
                }
              }
            }
          })
        }

      },
      (error) => {
        console.error('Failed to load projects:', error);
      }
    );
  }

  onSelectStatus(value: string) {
    this.setFormValue('status', value)
    this.selectedStatus = value;
  }

  onSelectReporter(value: string) {
    this.setFormValue('reporterId', value)
    this.reporterId = value;
  }

  onSelectPrority(value: string) {
    this.setFormValue('priority', value)
    this.priority = value;
  }

  getReporterName(value: string) {
    return this.users.find(data => data.id == value).name;
  }

  setFormValue(key, value) {
    this.form.get(key)?.setValue(value);
  }

  handleChangeAssignmentType(e) {
    this.assignedTo = []
    this.assignmentType = e.target.value;
  }

  // handleAssignTask(step, stepIndex) {
  //   this.selectedStepIndex = stepIndex
  //   this.selectedStep = step
  // }

  handleChangeAssignTo(e) {
    this.assignedTo = e;
    this.setFormValue('assignedTo', this.assignedTo)
  }


  close() {
    this.offcanvasRef.close();
  }



  onSubmit() {
    this.form.patchValue({
      reporterId: this.reporterId
    });

    if (!this.canSubmit(this.form)) return;
    this.form.markAsPending();
    let formData: any = {
      "taskName": this.form.value.taskName,
      "taskForm": this.form.value.taskForm,
      "instanceType": this.data.type === 'form' ? 'formInstance' : 'Datatable',
      "assignmentType": this.assignmentType,
      "status": this.selectedStatus,
      "instanceId": '',
      "operationType": 1,
      "priority": this.priority,
      "dueDate": this.selectedDate,
      "startDate": this.startDate,
      "assignedTo": this.assignedTo.map(data => data.id).join(","),
      "reporterId": this.form.value.reporterId,
      "projectId": this.selectedProject.id || null,
      "attachments": this.attachments,
      ...(this.data.isSubTask === true && { taskId: this.taskId })
    }

    if (this.data.taskType === 'workflow') {
      formData.emails = this.assignedTo.map(data => data?.createdBy.email)
      this.offcanvasRef.close(formData)
    } else {
      const callApi = this.taskId && this.data.isTaskEdit ? this.userTaskService.update(this.taskId, formData) : this.userTaskService.create(formData);
      callApi
        .subscribe(
          (response) => {
            if (this.taskId && this.data.isTaskEdit) {
              this.userTaskService.getActivityLog(formData, this.taskPreviousObject, this.activatedAccount, this.users, this.taskId);
            }
            this.offcanvasRef.close(formData)
            // this.dialogRef.close({ ...formData, id: response.id, accountId: response.assignedTo });
          },
          (err: ErrorMessage) => this.handleValidationErrors(err, this.form, this.fieldRejections),
          () => this.form.markAsUntouched()
        );
    }
  }
  getLogArray(log) {
    return JSON.parse(log)
  }

  splitBoldText(logString: string): { text: string, isBold: boolean }[] {
    // Split the string by '**' to identify bold and normal parts
    const parts = logString.split('**');

    // Map the parts into objects indicating whether they should be bold or not
    return parts.map((text, index) => ({
      text: text, // Trim spaces
      isBold: index % 2 === 1 // Bold text is in odd indices
    })); 
  }



  getInitial(logString: string): string {
    // Assuming the first bold part is the user's name
    const namePart = logString.split('**')[1];

    // Return the first letter of the name (if name exists)
    return namePart ? namePart.charAt(0).toUpperCase() : '';
  }

  createComment() {
    const api = this.editCommentId ? this.userTaskService.editComment({ taskId: this.taskId, comment: this.comments }, this.editCommentId) : this.userTaskService.createTask({ taskId: this.taskId, comment: this.comments });
    api.subscribe(() => {
      this.fetchComment();
      this.comments = '';
      this.editCommentId = null;
    })
  }

  fetchComment() {
    this.showComment = true;
    this.showHistory = false;
    this.showDocuments = false
    this.userTaskService.getTaskComment(this.taskId).subscribe((response) => {
      this.allCommments = response;
    });
  }

  fetchDocuments() {
    this.showComment = false;
    this.showHistory = false;
    this.showDocuments = true
  }

  loadHistory() {
    this.showHistory = true;
    this.showComment = false;
    this.showDocuments = false
  }

  editComment(id: string) {
    this.editCommentId = id;
    const comment = this.allCommments.rows.find(comment => comment.id === id);
    if (comment) {
      this.comments = comment.comment;
    }
  }

  deleteComment(id: string) {
    this.userTaskService.deleteComment(id).subscribe((response) => {
      this.fetchComment();
    });
  }

  cancelEditComment() {
    this.editCommentId = null;
    this.comments = '';
  }


  triggerFileInput() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }


  uploadFileData = {
    name: '',
    type: '',
    path: '',
    size: 0
  }

  // Attach files manually from the button
  async attachFiles(event: Event) {
    const target = event.target as HTMLInputElement;
    const files: FileList | null = target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileExtension = this.getFileExtension(file.name);
      this.uploadFileData = {
        name: file.name,
        type: fileExtension,
        path: '',
        size: file.size
      }

      try {
        const url = await this.performVideoUpload(file);
        this.attachments.push(url)
        this.onSubmit()

      } catch (err) {
        console.error("Error uploading file:", err);
      }
    }
  }


  /**
   * @description Extracts the file extension from the file name
   * @param {string} fileName - The name of the file
   * @returns {string} - The file extension
   */
  getFileExtension(fileName: string): string {
    const parts = fileName.split(".");
    return parts[parts.length - 1].toLowerCase();
  }


  /**
 * @description Performs the video upload to AWS S3 and returns the file URL
 * @param {File} file - The file to be uploaded
 * @returns {Promise<string>} - A promise that resolves to the file URL
 */
  performVideoUpload(file): Promise<string> {
    return new Promise((resolve, reject) => {
      this.fileUploadService.uploadCustomerImage(file, (err, data) => {
        if (err) {
          return reject(err);
        }
        const s3Prefix = 'https://s3-ap-southeast-2.amazonaws.com/static-dev-fusionflow-com-au/';
        const cfDistribution = 'https://static-dev-fusionflow-com-au/';
        const url = data.Location.replace(s3Prefix, cfDistribution);
        resolve(url);
      });
    });
  }
  downloadFile(url: string) {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.target = '_blank'
    anchor.download = url.split('/').pop() || 'download';
    anchor.click();
  }

  isImageFile(fileUrl: string): boolean {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
    return imageExtensions.some(ext => fileUrl.toLowerCase().includes(ext));
  }

  // Extract the file name from the URL
  getFileName(fileUrl: string): string {
    return fileUrl.split('/').pop() || 'Unknown file';
  }

  routeChangeHandle() {
    this.close()
  }
  handleAddReporter() {
    this.close()
    this.router.navigate(["/dashboard/config/users"])
  }
}
