import { CommonController } from "../util/common.controller";
import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-powerfull-features",
  styleUrls: ["./_powerfull-features.component.scss"],
  template: `
    <section class="tech-stack">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-11">
            <h4 class="title mb-0">Powerful Features</h4>
            <p class="description">
              The ultimate upgrade to delivering projects on time and on budget.
            </p>
          </div>
          <div class="col-12 features">
            <div class="row row-gap-5">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="card card-gradient gradient-1">
                  <div class="card-body">
                    <div class="text-start">
                      <h2 class="title">Forms</h2>
                      <p class="desc">
                        Design simple data collection forms for business
                        processes with automated pre-populating fields.
                      </p>
                    </div>
                  </div>
                  <div class="stack-img">
                    <img src="assets/img/features-1.png" alt="stack-img" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="card card-gradient gradient-2">
                  <div class="card-body">
                    <div class="text-start">
                      <h2 class="title">File Store</h2>
                      <p class="desc">
                        Securely store and files always-encrypted data stores.
                        Share files securely with multi-layered security to
                        ensure files are only seen only by the intended
                        audience.
                      </p>
                    </div>
                  </div>
                  <div class="stack-img">
                    <img src="assets/img/features-1.png" alt="stack-img" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="card card-gradient gradient-3">
                  <div class="card-body">
                    <div class="text-start">
                      <h2 class="title">Data Table</h2>
                      <p class="desc">
                        Create, store and organise arbitrary data. Associate
                        data tables to customers, forms and any other entity
                      </p>
                    </div>
                  </div>
                  <div class="stack-img">
                    <img src="assets/img/features-1.png" alt="stack-img" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="card card-gradient gradient-4">
                  <div class="card-body">
                    <div class="text-start">
                      <h2 class="title">Templates</h2>
                      <p class="desc">
                         Pre-built workflow templates and components for
                        individual functions and whole-of-business operations.
                      </p>
                    </div>
                  </div>
                  <div class="stack-img">
                    <img src="assets/img/features-1.png" alt="stack-img" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="card card-gradient gradient-5">
                  <div class="card-body">
                    <div class="text-start">
                      <h2 class="title">Users</h2>
                      <p class="desc">
                        Invite friends, colleagues and employees to access
                        workflow functionality. Compartmentalise users with user
                        groups
                      </p>
                    </div>
                  </div>
                  <div class="stack-img">
                    <img src="assets/img/features-1.png" alt="stack-img" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="card card-gradient gradient-6">
                  <div class="card-body">
                    <div class="text-start">
                      <h2 class="title">Email</h2>
                      <p class="desc">
                        Design simple, custom email templates to send when your
                        application does something.
                      </p>
                    </div>
                  </div>
                  <div class="stack-img">
                    <img src="assets/img/features-1.png" alt="stack-img" />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="card card-gradient gradient-7">
                  <div class="card-body pb-5">
                    <div class="row align-items-center justify-content-between">
                      <div class="col-12 col-lg-4">
                        <div class="text-start">
                          <h2 class="title">Workflows</h2>
                          <p class="desc">
                            Turn behaviours, actions and events into direct,
                            actionable events like sending emails, generating
                            tasks and updating data
                          </p>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="stack-img">
                          <img
                            class="w-100"
                            src="assets/img/features-2.png"
                            alt="stack-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  `,
})
export class PowefullFeaturesComponent extends CommonController {}
