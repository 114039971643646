import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserDashboardService } from '../../services/account.service';
import { JwtService } from '../../services/jwt.service';
import { Projectservice } from '../../services/project.service';
import { ICreateProject } from '../../model/project.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./_dashboard.component.scss']
})
export class DashboardComponent {
  currentPath: string = ''
  isShowNavigation: boolean = true;
  showConfigClass: boolean;
  showConfigClassWorkFlow: boolean;
  activatedAccount: any
  selectedProject: ICreateProject
public cardToShow: boolean
  constructor(public router: Router, public route: ActivatedRoute, private jwtService: JwtService, private projectService: Projectservice) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showConfigClass = this.router.url.includes('config');
        this.router.events.subscribe(() => {
          const currentRoute = this.getCurrentRoute(this.route);
          this.showConfigClassWorkFlow = currentRoute.snapshot.data['showMenu'];
        });
        if (event.url.split("/").length > 3 || !this.currentPath.includes("task")) {
          this.isShowNavigation = false
        } else {
          this.isShowNavigation = true
        }
        this.currentPath = event.url
      }
    });
  }
  ngOnInit() {
    this.monitorJwtData();
  }

  private monitorJwtData(): void {
    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        if (data) {
          this.activatedAccount = data.account;
          // setTimeout(() => {
          this.loadProjects()
          // }, 2000);

        }
      },
    });
  }

  private loadProjects() {
    this.projectService.projects$.subscribe(
      (projects: ICreateProject[]) => {
        console.log("projects", projects)
        this.selectedProject = projects.find(item => item.id === this.projectService.getSelectedProject(this.activatedAccount.id)) ?? projects[0];
      },
      (error) => {
        console.error('Failed to load projects:', error);
      }
    );
  }


  private getCurrentRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

}
