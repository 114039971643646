import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { merge, Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { ErrorMessage, FieldRejection } from '../model/api.model';
import { CommonController } from './common.controller';
import { logger } from './Logger';
import { isString } from './utils';

export const showError = (form: UntypedFormGroup | undefined, fieldName: string, errorName: string | any[]): boolean => {
    // const signature = "formUtils.showError: ";
    if (!form || form.disabled || !form.controls[fieldName] || (isString(errorName) && (form.controls[fieldName].disabled || !form.controls[fieldName].touched)))
        return false;

    if (Array.isArray(errorName)) {
        for (let i = 0; i < errorName.length; i++) {
            const errorNameActual = errorName[i];

            if (!form.controls[fieldName].disabled && form.controls[fieldName].hasError(errorNameActual))
                return true;
        }

        return false;
    }

    const result = form.controls[fieldName].hasError(errorName);

    // logger.silly(signature+`form field[${fieldName}] errorName[${errorName}] hasError[${result}]`);

    return form.controls[fieldName].hasError(errorName);
}

export const getError = (form: UntypedFormGroup, fieldName: string, errorName: string) => {
    if (!showError(form, fieldName, errorName)) return "";

    if (errorName)
        return form.controls[fieldName].getError(errorName);
    else
        return form.controls[fieldName].valid;
}

export const canSubmit = (form: UntypedFormGroup | undefined, opts?: {
  logErrors: true
}) => {
    const options = Object.assign({
      logErrors: false,
    }, opts || {})
    const signature = `canSubmit: `;
    if (!form || form.pending) return false;

    const allFields = Object.keys(form.controls);

    const allErrors = allFields.reduce( (obj, fieldKey) => {
        const errors = form.controls[fieldKey].errors || [];
        if (errors) {
            const errorKeys = Object.keys(errors);

            if (errorKeys.length > 1 || ( errorKeys.length > 0 && errorKeys[0] !== 'serverValidation') ) {
                obj[fieldKey] = errors;
            }
        }

        return obj;
    }, {} as Record<string, ValidationErrors>);

    const result = Object.keys(allErrors).length === 0;

    if( !result && options.logErrors ) {
        logger.silly(signature + `Errors[${JSON.stringify(allErrors)}]`);
    }

    return result
}

/**
 * @description Display helper for outputting server-side validation errors for any given form
 *
 * @param {FieldRejection[]} targetArray The array that should contain the field rejections (if any)
 * @returns {string|undefined}
 */
export const displayValidationErrors = (rejections?: FieldRejection[]): string | undefined => {
    if (!rejections || !rejections.length) return undefined;

    const errorStrs = rejections.map(rejection => {
        let result = `<strong>Invalid ${rejection.field}. Expected Value(s): </strong><br />`;

        if (Array.isArray(rejection.expected)) {
            return result + rejection.expected.join("<br />")
        } else {
            return result + rejection.expected;
        }
    });

    if (errorStrs.length) {
        return errorStrs.join("<br /><br />");
    }

    return undefined;
}

/**
 *
 * Modifies the target array and attaches field rejections from the error message. If the error message is
 * blank, undefined, or contains no rejections, the target array will be cleared
 *
 * @param {ErrorMessage} err
 * @param {FieldRejection[]} targetArray The array that should contain the field rejections (if any)
 */
export const handleValidationErrors = (err: ErrorMessage, targetForm: UntypedFormGroup, targetArray: FieldRejection[]): void => {
    const signature = "handleValidationErrors: ";
    logger.silly("Handling Validation Errors");
    logger.silly(err);

    // Always clean the validation error array when processing a new error set
    targetArray.splice(0, targetArray.length);

    if (err.rejectedFields && err.rejectedFields.length) {
        /** We must be careful to not change the array as the validation relies on it */
        targetArray.push(...err.rejectedFields);
    }

    // Refresh the field validation
    Object.keys(targetForm.controls).forEach(field => targetForm.controls[field].updateValueAndValidity());
}
