import { Injectable } from "@angular/core";
import { apiCallWrapper } from '../api/api.util';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, catchError, map, Observable, ReplaySubject, shareReplay, tap } from 'rxjs';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { ProjectApi, ProjectStatusApi, ProjectUsersApi } from "../api/project.api";
import { ICreateProject, ICreateProjectStatus } from "../model/project.model";
import { AuthService } from "./auth.service";
import { GenericService } from "./generic.service";
import { GenericApi } from "../api/generic.api";
import { Router } from "@angular/router";

@Injectable()
export class Projectservice {
  private projectsSubject = new BehaviorSubject<ICreateProject[]>([]);
  public projects$ = this.projectsSubject.asObservable();
  private readonly localStorageKey = 'selectedProjectIdByUser';
  private cachedProjects$: Observable<ICreateProject[]> | null = null;;


  constructor(
    private projectApi: ProjectApi,
    public notifications: NotificationsService,
    private authService: AuthService,
    private router: Router
  ) { }
  public readonly createProject = (payLoad: ICreateProject): Observable<ICreateProject> => {
    return apiCallWrapper(this.projectApi.create(payLoad), {
      notificationsService: this.notifications,
      action: "Creating Project"
    }).pipe(
      tap(() => {
        this.refreshProjects();  // Refresh cached projects after creation
      })
    );
  }

  public readonly deleteProject = (id: string): Observable<void> => {
    return apiCallWrapper(this.projectApi.delete(id, ''), {
      notificationsService: this.notifications,
      action: "Deleting Project"
    }).pipe(
      tap(() => {
        this.notifications.success('Project deleted successfully');
        this.refreshProjects(); // Refresh cached projects after deletion
      }),
      catchError(error => {
        this.notifications.error('Failed to delete project');
        throw error;
      })
    );
  };


  public readonly updateProject = (id: string, payLoad: ICreateProject): Observable<ICreateProject> => {
    return apiCallWrapper(this.projectApi.update(id, payLoad), {
      notificationsService: this.notifications,
      action: "Updating Project"
    }).pipe(
      tap(() => {
        this.refreshProjects();  // Refresh cached projects after creation
      })
    );
  }


  public readonly getProjectDetails = (id: string): Observable<ICreateProject> => {
    return apiCallWrapper(this.projectApi.get(id), {
      notificationsService: this.notifications,
      action: "Project Detail"
    }).pipe(
      tap(() => {
      })
    );
  }

  public readonly list = (query: IQueryFilter): Observable<QueryResult<ICreateProject>> => {
    return apiCallWrapper(this.projectApi.list(query), {
      notificationsService: this.notifications,
      action: "List Projects"
    }).pipe(
      tap(res => {
        if (res.rows.length === 0) {
          this.router.navigate(['/dashboard/manage/create-project'])
        }
        this.projectsSubject.next(res.rows);
      })
    );
  }

  public readonly getProjects = (): Observable<ICreateProject[]> => {
    if (!this.cachedProjects$) {
      this.cachedProjects$ = this.list(new IQueryFilter({ limit: 10 })).pipe(
        map(res => res.rows),
        tap(projects => this.projectsSubject.next(projects)),
        shareReplay(1)
      );
    }
    return this.cachedProjects$;
  }

  private refreshProjects(): void {
    this.cachedProjects$ = null;  // Clear cache
    this.getProjects().subscribe();  // Refresh cache
  }

  // Set selected project ID in localStorage
  setSelectedProject(accountId: string, projectId: string): void {
    const userSelection = this.getUserSelection();
    userSelection[accountId] = projectId;
    localStorage.setItem(this.localStorageKey, JSON.stringify(userSelection));
    window.location.reload()
  }

  // Get selected project ID from localStorage
  getSelectedProject(accountId: string): string | null {
    const userSelection = this.getUserSelection();
    return userSelection[accountId] || null;
  }

  // Clear selected project ID from localStorage
  clearSelectedProject(accountId: string): void {
    const userSelection = this.getUserSelection();
    delete userSelection[accountId];
    localStorage.setItem(this.localStorageKey, JSON.stringify(userSelection));

  }

  private getUserSelection(): { [accountId: string]: string } {
    const storedData = localStorage.getItem(this.localStorageKey);
    return storedData ? JSON.parse(storedData) : {};
  }
}


@Injectable()
export class ProjectStatusService {
  private projectStatusSubject = new BehaviorSubject<ICreateProjectStatus[]>([]);
  public projectStatus$ = this.projectStatusSubject.asObservable();

  constructor(
    public projectStatusApi: ProjectStatusApi,
    public notifications: NotificationsService
  ) { }

  public readonly list = (query: IQueryFilter): Observable<ICreateProjectStatus[]> => {
    if (this.projectStatusSubject.getValue().length > 0) {
      return this.projectStatus$;
    } else {
      return apiCallWrapper(this.projectStatusApi.list(query), {
        notificationsService: this.notifications,
        action: "List Projects"
      }).pipe(
        tap(res => {
          this.projectStatusSubject.next(res.rows);
        }),
        map(res => res.rows)
      );
    }
  }
  public clearCache(): void {
    this.projectStatusSubject.next([]);
  }
}


@Injectable()
export class ProjectUsersService extends GenericService<any> {
  constructor(
    public projectUsersApi: ProjectUsersApi,
    public notifications: NotificationsService
  ) {
    super(projectUsersApi as GenericApi<any>, notifications);
  }
}
