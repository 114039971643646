import { Component } from '@angular/core';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./_help-page.component.scss']
})
export class HelpPageComponent {

}
