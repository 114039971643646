import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SimpleNotificationsModule } from "angular2-notifications";
import { ServiceWorkerModule } from "@angular/service-worker";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./auth/login/login.component";
import { DemoComponent } from "./auth/demo/demo.component";
import { FooterComponent } from "./template/footer/footer.component";
import { HeaderComponent } from "./template/header/header.component";
import { MainTemplateComponent } from "./template/main-template/main-template.component";
import { ThemeSelectorComponent } from "./template/theme-selector/theme-selector.component";
import { AuthService } from "./services/auth.service";
import { PublicService } from "./services/public.service";
import { publicApi } from "./api/public.api";
import { UtilService } from "./services/util.service";
import { RegisterComponent } from "./auth/register/register.component";
import { AuthApi } from "./api/auth.api";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CommonController } from "./util/common.controller";
import { ResetComponent } from "./auth/reset.component";
import { JwtService } from "./services/jwt.service";
import { StorageService } from "./services/storage.service";
import { ConsoleComponent } from "./console/console.component";
import { AuthGuard } from "./guard/auth.guard";
import { ForgotComponent } from "./auth/forgot.component";
import { ContactFormComponent } from "./home/contact-form.component";
import { HomeBannerComponent } from "./home/banner.component";
import { HomeWorkflowComponent } from "./home/workflow-info.component";
import { ClientReviewComponent } from "./home/client-review.component";
import { WhyFusionflowComponent } from './home/why-fusionflow.component'
import { FaqComponent } from './home/faq.component'
import { MailService } from "./services/mail.service";
import { AccountPlanBuyService, AccountPlanService, UserAccountService, UserSwitchAccountService, UserTaskService, MyTaskService, ChangeTaskStatusService, PublicFormService, PublicFormInstanceService, GetWorkflowInstanceService, UserDashboardService, PublicFormInstanceServiceV2, CreateCheckoutService, TaskActivityService, TaskBulkUpdateService, TaskBulkDeleteService } from "./services/account.service";
import { MailApi } from "./api/mail.api";
import { AccountPlanApi, AccountPlanBuyApi, UserAccountsApi, UserSwitchAccountsApi, UserTaskApi, MyTaskApi, ChangeTaskStatusApi, PublicFormApi, PublicFormInstanceApi, GetWorkflowInstanceApi, UserDashboardApi, PublicFormInstanceApiV2, CreateCheckoutApi, TaskActivityAPI, TaskBulkUpdateApi, TaskBulkDeleteApi } from "./api/account.api";
import { AppIconComponent } from "./directives/app-icon.component";
import { environment } from "../../environments/environment";
import { AppListComponent } from "./directives/app-list.component";
import { UiModule } from "../ui/ui.module";
import { SecurityModule } from "../security/security.module";
import { JwtInterceptor } from "./interceptor/jwt.interceptor";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import * as Sentry from "@sentry/angular-ivy";
import { Angulartics2Module } from "angulartics2";
import { PricingComponent } from './additionalPages/pricing/pricing.component';
import { SuccessPageComponent } from "./additionalPages/pricing/statusURL/payment-success.component";
import { CancelPageComponent } from "./additionalPages/pricing/statusURL/payment-cancel.component";
import { AboutComponent } from './additionalPages/about/about.component';
import { FeaturesComponent } from './additionalPages/features/features.component';
import { DashboardComponent } from './additionalPages/dashboard/dashboard.component';
import { CompanyListComponent } from './additionalPages/company-list/company-list.component';
import { UserDashbaordComponent } from './additionalPages/user-dashbaord/user-dashbaord.component';
import { TaskBoardComponent } from './additionalPages/task-board/task-board.component';
import { AddAccountDialogComponent } from './additionalPages/company-list/add-account-dialog/add-account-dialog.component';
import { PublicFormsComponent } from './additionalPages/public-forms/public-forms.component';
import { PublicFormsParticipentDialogComponent } from './additionalPages/public-forms/public-form-participent.component';
import { FormsInstanceService } from "../forms-instance/service/forms-instance.service";
import { FormsInstanceApi } from "../forms-instance/api/forms-instance.api";
import { MyUserService, myGroupService } from "../user/service/user.service";
import { MyGroupApi, MyUserApi } from "../user/api/user.api";
import { WorkflowMessageApi, WorkflowPublicTemplateApi, WorkflowTemplateApi } from "../workflow-template/api/workflow-template.api";
import { WorkflowMessageService, WorkflowPublicTemplateService, WorkflowTemplateService } from "../workflow-template/service/workflow-template.service";
import { WorkflowInstanceService } from "../workflow/service/workflow-instance.service";
import { WorkflowInstanceApi } from "../workflow/api/workflow-instance.api";
import { FormsTemplateApi } from "../forms-template/api/forms-template.api";
import { FormsTemplateService } from "../forms-template/service/forms-template.service";
import { EmailTemplateApi } from "../email-template/api/email-template.api";
import { EmailTemplateService } from "../email-template/service/email-template.service";
import { DataTableApi } from "../data-table/api/data-table.api";
import { DataTableService } from "../data-table/service/data-table.service";
import { FileUploadService } from "./services/fileUpload.service";
import { FileUploadApi } from "./api/fileUpload.api";
import { SessionApi } from './api/session.api'
import { SessionService } from './services/session.service';
import { MenuComponent } from './template/menu/menu.component';
import { ConfigFeatureComponent } from './home/config-feature.component';
import { TechStackComponent } from './home/tech-stack.component';
import { TechComparisionComponent } from './home/tech-comparison.component';
import { PowefullFeaturesComponent } from './home/powerfull-features.component';
import { MyTaskComponent } from './additionalPages/my-task/my-task.component';
import { ChatComponent } from './chat/chat.component';
import { CreateProjectComponent } from './project/create-project/create-project.component';
import { ChatService } from "./services/chat.service";
import { ChatApi } from "./api/chat.api";
import { Projectservice, ProjectStatusService, ProjectUsersService } from "./services/project.service";
import { ProjectApi, ProjectStatusApi, ProjectUsersApi } from "./api/project.api";
import { DraggableTaskViewComponent } from './additionalPages/my-task/draggable-task-view/draggable-task-view.component';
import { MoveTaskDialogComponent } from './additionalPages/my-task/move-task-dialog/move-task-dialog.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ListProjectComponent } from './project/list-project/list-project.component';
import { ManageGroupMambersComponent } from './chat/manage-group-mambers/manage-group-mambers.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { TooltipDirective } from "../ui/directive/tooltip.directive";
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LoaderComponent } from "../ui/view/loader.component";
import { HelpPageComponent } from './additionalPages/help-page/help-page.component';
import { TermsServicesComponent } from './additionalPages/terms-services/terms-services.component';
import { PrivacypolicyComponent } from './additionalPages/privacypolicy/privacypolicy.component';
// import { TimeAgoPipe } from "./pipe/timeAgo.pipe";
const config: SocketIoConfig = { url: environment.endpoint, options: {} };

@NgModule({
  declarations: [
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ResetComponent,
    ConsoleComponent,
    ForgotComponent,

    /** Home Component Includes */
    HomeBannerComponent,
    TechStackComponent,
    PowefullFeaturesComponent,
    TechComparisionComponent,
    HomeWorkflowComponent,
    ClientReviewComponent,
    WhyFusionflowComponent,
    FaqComponent,

    //Templates
    CommonController,
    MainTemplateComponent,
    ThemeSelectorComponent,

    /* Includes */
    FooterComponent,
    HeaderComponent,

    // Apps
    AppIconComponent,
    AppListComponent,
    // TooltipDirective,


    /* Forms */
    ContactFormComponent,
    PricingComponent,
    SuccessPageComponent,
    CancelPageComponent,

    AboutComponent,
    FeaturesComponent,
    DashboardComponent,
    CompanyListComponent,
    UserDashbaordComponent,
    TaskBoardComponent,
    AddAccountDialogComponent,
    PublicFormsComponent,
    PublicFormsParticipentDialogComponent,
    DemoComponent,
    MenuComponent,
    ConfigFeatureComponent,
    MyTaskComponent,
    LoaderComponent,
    ChatComponent,
    CreateProjectComponent,
    DraggableTaskViewComponent,
    ListProjectComponent,
    ManageGroupMambersComponent,
    MoveTaskDialogComponent,
    HelpPageComponent,
    TermsServicesComponent,
    PrivacypolicyComponent,
    // TimeAgoPipe
  ],
  imports: [
    BrowserModule,
    SocketIoModule.forRoot(config),
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    DragDropModule,
    CarouselModule,
    /* Alerts - https://www.npmjs.com/package/angular2-notifications */
    BrowserAnimationsModule,
    SimpleNotificationsModule.forRoot(),

    // Google Analytics Support
    Angulartics2Module.forRoot(),

    //Service Worker (Offline Mode)
    ServiceWorkerModule.register("/ngsw-config.js", {
      enabled: environment.production,
    }),

    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),

    /** App Modules */

    UiModule,
    SecurityModule,
  ],
  providers: [
    //Vendor
    CookieService,

    // Guards
    AuthGuard,

    // Spark Services
    AuthService,
    MailService,
    AccountPlanService,
    AccountPlanBuyService,
    CreateCheckoutService,
    CreateCheckoutApi,
    UserAccountService,
    UserSwitchAccountService,
    UserTaskService,
    MyTaskService,
    ChangeTaskStatusService,
    TaskBulkUpdateService,
    TaskBulkDeleteService,
    PublicFormService,
    FormsInstanceService,
    FileUploadService,
    UtilService,
    JwtService,
    StorageService,
    PublicService,
    PublicFormInstanceService,
    PublicFormInstanceApiV2,
    PublicFormInstanceServiceV2,
    WorkflowMessageService,
    WorkflowMessageApi,
    FileUploadApi,

    FormsTemplateService,
    FormsTemplateApi,

    EmailTemplateApi,
    EmailTemplateService,

    DataTableApi,
    DataTableService,

    // Spark Apis
    AuthApi,
    MailApi,
    publicApi,
    AccountPlanApi,
    AccountPlanBuyApi,
    UserAccountsApi,
    UserSwitchAccountsApi,
    UserTaskApi,
    MyTaskApi,
    ChangeTaskStatusApi,
    TaskBulkUpdateApi,
    TaskBulkDeleteApi,
    PublicFormApi,
    PublicFormInstanceApi,
    FormsInstanceApi,
    MyUserService,
    MyUserApi,
    myGroupService,
    MyGroupApi,
    WorkflowTemplateApi,
    WorkflowTemplateService,
    GetWorkflowInstanceService,
    WorkflowPublicTemplateApi,
    WorkflowPublicTemplateService,
    GetWorkflowInstanceApi,
    UserDashboardApi,
    UserDashboardService,
    TaskActivityAPI,
    TaskActivityService,
    WorkflowInstanceApi,
    WorkflowInstanceService,
    SessionApi,
    SessionService,

    ChatService,
    ChatApi,
    Projectservice,
    ProjectApi,
    ProjectStatusApi,
    ProjectStatusService,
    ProjectUsersApi,
    ProjectUsersService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },

    /** Sentry */
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [MainTemplateComponent],
})
export class AppModule { }
