<app-menu></app-menu>
<section
  class="dashboard"
  [class.config]="showConfigClass"
  [class.workflow-config]="showConfigClassWorkFlow"
  [ngStyle]="{
    padding:
      currentPath.includes('chat') ||
      currentPath.includes('create-form') ||
      currentPath.includes('add-datatable') ||
      currentPath.includes('edit-form') ||
      currentPath.includes('edit-datatable')
        ? '0px'
        : ''
  }"
>
  <div
    *ngIf="
      !currentPath.includes('chat') ||
      !currentPath.includes('create-project') ||
      !currentPath.includes('add-datatable') ||
      !currentPath.includes('edit-form') ||
      !currentPath.includes('edit-datatable')
    "
  >
    <!-- User-welcome content start here...-->
    <div
      *ngIf="currentPath === '/dashboard'"
      class="welcome-user d-flex align-items-center justify-content-between"
    >
      <div>
        <h4 class="mb-0">Welcome to Dashboard {{ activatedAccount.name }}</h4>
        <p class="mb-0">Project: {{ selectedProject?.projectName }}</p>
      </div>
      <div class="d-flex align-items-center flex-gap">
        <div class="position-relative">
          <button
            class="btn btn-danger-gradient"
            type="button"
            (click)="cardToShow = true"
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
              >
                <path
                  d="M6.31616 0.5C3.00785 0.5 0.316162 3.19169 0.316162 6.5C0.316162 7.50308 0.572162 8.49385 1.0577 9.37877L0.334008 11.9117C0.287854 12.0729 0.332778 12.2465 0.451547 12.3646C0.539547 12.4526 0.657085 12.5 0.777701 12.5C0.820162 12.5 0.862624 12.4945 0.90447 12.4822L3.43739 11.7585C4.32232 12.2446 5.31309 12.5 6.31616 12.5C9.62447 12.5 12.3162 9.80831 12.3162 6.5C12.3162 3.19169 9.62447 0.5 6.31616 0.5ZM6.70385 8.98C6.6177 9.06615 6.49462 9.11539 6.3777 9.11539C6.31616 9.11539 6.25462 9.10308 6.19924 9.07846C6.14386 9.05385 6.09462 9.02308 6.05155 8.98C6.00847 8.93692 5.97155 8.88769 5.94693 8.83231C5.92847 8.77692 5.91616 8.71538 5.91616 8.65385C5.91616 8.59231 5.92847 8.53077 5.94693 8.47538C5.97155 8.42 6.00847 8.37077 6.05155 8.32769C6.09462 8.28462 6.14386 8.25385 6.19924 8.22923C6.31001 8.18 6.43924 8.18 6.55001 8.22923C6.61155 8.25385 6.66078 8.28462 6.70385 8.32769C6.74693 8.37077 6.7777 8.42 6.80232 8.47538C6.82693 8.53077 6.83924 8.59231 6.83924 8.65385C6.83924 8.77692 6.79001 8.89385 6.70385 8.98ZM7.70385 6.25569C7.68785 6.27662 7.67001 6.29569 7.65155 6.31354L6.9297 6.98185C6.86939 7.07108 6.83801 7.17138 6.83801 7.27538C6.83801 7.53015 6.63124 7.73692 6.37647 7.73692C6.1217 7.73692 5.91493 7.53015 5.91493 7.27538C5.91493 6.95662 6.01893 6.65138 6.21647 6.39354C6.23247 6.37262 6.25032 6.35354 6.26939 6.33569L6.99124 5.66739C7.08847 5.52462 7.11185 5.35231 7.05647 5.18985C6.99616 5.01569 6.85401 4.88708 6.66632 4.83846C6.48047 4.78923 6.33401 4.80031 6.21832 4.86923C6.02878 4.98308 5.9297 5.24585 5.90816 5.33754C5.84847 5.58369 5.6017 5.74062 5.35493 5.68154C5.10816 5.62492 4.95308 5.38185 5.00785 5.13446C5.02385 5.06246 5.17893 4.42308 5.73401 4.084C5.96478 3.94308 6.35555 3.804 6.90016 3.94554C7.3857 4.07231 7.77032 4.42492 7.93032 4.89015C8.0897 5.35354 8.00539 5.86431 7.70509 6.25631L7.70385 6.25569Z"
                  fill="white"
                />
              </svg>
            </span>
            Help
          </button>

          <div class="collabrator-content help share" [class.show]="cardToShow">
            <!-- content head start here... -->
            <div
              class="card-head d-flex align-items-start justify-content-between gap-3 w-100"
            >
              <div>
                <p class="desc mb-0">How we can help you</p>
              </div>
              <button
                class="btn btn-cancel p-0 bg-transparent border-0"
                type="button"
                (click)="cardToShow = false"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div class="form-group mb-0 w-100">
              <label for="firstName" class="form-label">Search here</label>
              <div class="input-with-icon">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M8.93423 1.86825C9.02683 1.49315 9.56009 1.49315 9.65269 1.86825L10.4089 4.93144C10.8042 6.53261 12.0543 7.78276 13.6555 8.17803L16.7187 8.93423C17.0938 9.02683 17.0938 9.56009 16.7187 9.65269L13.6555 10.4089C12.0543 10.8042 10.8042 12.0543 10.4089 13.6555L9.65269 16.7187C9.56009 17.0938 9.02683 17.0938 8.93423 16.7187L8.17803 13.6555C7.78276 12.0543 6.53259 10.8042 4.93144 10.4089L1.86825 9.65269C1.49315 9.56009 1.49315 9.02683 1.86825 8.93423L4.93144 8.17803C6.53261 7.78276 7.78276 6.53259 8.17803 4.93144L8.93423 1.86825ZM3.19359 1.14067C3.2399 0.95311 3.50653 0.95311 3.55284 1.14067L3.61112 1.37676C3.82522 2.24404 4.50239 2.9212 5.36967 3.13531L5.60576 3.19359C5.79332 3.2399 5.79332 3.50653 5.60576 3.55284L5.36967 3.61112C4.50239 3.82522 3.82522 4.50239 3.61112 5.36967L3.55284 5.60576C3.50653 5.79332 3.2399 5.79332 3.19359 5.60576L3.13531 5.36967C2.9212 4.50239 2.24404 3.82522 1.37676 3.61112L1.14067 3.55284C0.95311 3.50653 0.95311 3.2399 1.14067 3.19359L1.37676 3.13531C2.24404 2.9212 2.9212 2.24404 3.13531 1.37676L3.19359 1.14067Z"
                      stroke="url(#paint0_linear_1666_8154)"
                      stroke-width="1.5"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1666_8154"
                        x1="1"
                        y1="9"
                        x2="17"
                        y2="9"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#6F00FF" />
                        <stop offset="1" stop-color="#FC1885" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  placeholder="Tasks, Projects, workflow, Form instances more...."
                />
                <button class="btn border-0 p-0" type="button">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.461517 4.07013C-0.392243 1.93575 1.84465 -0.122458 3.90075 0.905592L15.618 6.76421C17.4606 7.68554 17.4606 10.315 15.618 11.2363L3.90074 17.0949C1.84464 18.123 -0.392233 16.0648 0.461517 13.9304L2.43357 9.00024L0.461517 4.07013ZM3.45353 1.80001C2.21987 1.18318 0.877737 2.41812 1.38999 3.69874L3.43633 8.81454C3.48401 8.93374 3.48401 9.06674 3.43633 9.18594L1.38999 14.3018C0.877737 15.5824 2.21987 16.8173 3.45353 16.2005L15.1708 10.3419C16.2764 9.78914 16.2764 8.21144 15.1708 7.65864L3.45353 1.80001Z"
                        fill="black"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.47217 8.5H6.97217V9.5H2.47217V8.5Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            <div>
              <h5>Suggestions</h5>
              <div class="d-flex flex-wrap column-gap-3 row-gap-4 suggestion">
                <button
                  class="btn btn-light-primary justify-content-center"
                  type="button"
                >
                  <span class="icon">
                    <svg
                      width="11"
                      height="14"
                      viewBox="0 0 21 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.847229 3.18173V24.8659H17.699V21.7682H20.6728V0.0839844H3.82106V3.18173H0.847229ZM16.7077 23.8334H1.83851V4.21431H13.2382V7.82835H16.7077V23.8334ZM14.2295 4.94434L16.0068 6.79576H14.2295V4.94434ZM4.81234 1.11657H19.6815V20.7356H17.699V7.09831L13.939 3.18173H4.81234V1.11657Z"
                        fill="#781DEE"
                      ></path>
                      <path
                        d="M3.82074 9.89364H14.7248V10.9262H3.82074V9.89364ZM3.82074 12.9914H14.7248V14.024H3.82074V12.9914ZM3.82074 16.0891H14.7248V17.1217H3.82074V16.0891ZM3.82074 20.2195H8.28149V21.252H3.82074V20.2195ZM10.264 20.2195H14.7248V21.252H10.264V20.2195ZM7.29021 6.7959H11.2553V7.82848H7.29021V6.7959Z"
                        fill="#781DEE"
                      ></path>
                    </svg>
                  </span>
                  How to Create Form instances
                </button>
                <button
                  class="btn btn-light-primary justify-content-center"
                  type="button"
                >
                  <span class="icon">
                    <svg
                      width="20"
                      height="15"
                      viewBox="0 0 32 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.01571 8.63133H3.0163L24.1972 8.63133L24.1978 8.63133C24.4503 8.63103 24.6924 8.53059 24.8709 8.35205C25.0495 8.17351 25.1499 7.93143 25.1502 7.67895V7.67835V2.30496V2.30436C25.1499 2.05186 25.0494 1.80979 24.8709 1.63125C24.6923 1.45272 24.4502 1.35231 24.1977 1.35205H24.1972L3.0163 1.35205L3.01578 1.35205C2.76328 1.35231 2.52119 1.45272 2.34263 1.63125C2.16407 1.80979 2.06362 2.05186 2.06332 2.30437V2.30496L2.06332 7.67835L2.06332 7.67894C2.06362 7.93144 2.16405 8.17351 2.3426 8.35205L2.69615 7.9985L2.3426 8.35205C2.52114 8.53059 2.76321 8.63103 3.01571 8.63133ZM8.07819 21.5976H8.07878H29.2597H29.2603C29.5128 21.5973 29.7549 21.4968 29.9334 21.3183C30.1119 21.1398 30.2124 20.8977 30.2127 20.6452V20.6446V15.2712V15.2706C30.2124 15.0181 30.1119 14.776 29.9334 14.5975C29.7548 14.419 29.5127 14.3186 29.2602 14.3183H29.2597H8.07878H8.07826C7.82576 14.3186 7.58367 14.419 7.40511 14.5975C7.22655 14.776 7.1261 15.0181 7.1258 15.2706V15.2712L7.1258 20.6446L7.1258 20.6452C7.1261 20.8977 7.22653 21.1398 7.40508 21.3183C7.58362 21.4968 7.8257 21.5973 8.07819 21.5976ZM22.2247 3.84347C22.2247 3.85324 22.2209 3.86262 22.2139 3.86954C22.207 3.87645 22.1976 3.88033 22.1879 3.88033H5.02564C5.01586 3.88033 5.00649 3.87645 4.99957 3.86954C4.99266 3.86263 4.98878 3.85325 4.98878 3.84347C4.98878 3.83369 4.99266 3.82432 4.99957 3.81741C5.00649 3.81049 5.01586 3.80661 5.02564 3.80661H22.1879C22.1976 3.80661 22.207 3.81049 22.2139 3.81741C22.2209 3.82432 22.2247 3.8337 22.2247 3.84347Z"
                        stroke="#6F00FF"
                      ></path>
                    </svg>
                  </span>
                  How to create Workflow
                </button>
                <button
                  class="btn btn-light-primary justify-content-center"
                  type="button"
                >
                  <span class="icon">
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 28 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.8356 6.62858V6.62111C23.8356 4.49695 22.1137 2.77498 19.9895 2.77498H10.704L8.57047 1.0698C8.40003 0.933586 8.18834 0.859375 7.97015 0.859375H4.59919C2.47502 0.859375 0.753052 2.58135 0.753052 4.70551V16.2439C0.753052 18.3681 2.47502 20.09 4.59919 20.09H22.3881C23.6697 20.09 24.7974 19.2447 25.157 18.0147L27.4052 10.3224C27.9448 8.47591 26.5602 6.62858 24.6364 6.62858H23.8356ZM4.59919 2.78244H7.63313L9.76664 4.48763C9.93708 4.62384 10.1488 4.69805 10.367 4.69805H19.9895C21.0516 4.69805 21.9126 5.55902 21.9126 6.62111V6.62858H7.00279C5.72076 6.62858 4.59255 7.47473 4.23357 8.70549L2.67612 14.0454V4.70551C2.67612 3.64344 3.53711 2.78244 4.59919 2.78244ZM6.07972 9.24395C6.19939 8.8337 6.57544 8.55164 7.00279 8.55164H24.6364C25.2777 8.55164 25.7393 9.16743 25.5593 9.78293L23.311 17.4753C23.1912 17.8852 22.8152 18.167 22.3881 18.167H4.75922C4.11819 18.167 3.65665 17.5516 3.83615 16.9362L6.07972 9.24395Z"
                        fill="#6F00FF"
                      ></path>
                    </svg>
                  </span>
                  User storage and files
                </button>
                <button
                  class="btn btn-light-primary justify-content-center"
                  type="button"
                >
                  <span class="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="13"
                      viewBox="0 0 17 13"
                      fill="none"
                    >
                      <path
                        d="M0.823669 0.329199H0.723669V0.429199V12.6218V12.7218H0.823669H16.0645H16.1645V12.6218V0.429199V0.329199H16.0645H0.823669ZM10.1222 7.18756H1.4317V3.06933H10.1222V7.18756ZM1.4317 12.0138V7.89558H10.1222V12.0138H1.4317ZM15.4564 12.0138H10.8302V7.89558H15.4564V12.0138ZM15.4564 7.18756H10.8302V3.06933H15.4564V7.18756Z"
                        fill="#6F00FF"
                        stroke="white"
                        stroke-width="0.2"
                      ></path>
                    </svg>
                  </span>
                  How to Add Data tables
                </button>
                <button
                  class="btn btn-light-primary justify-content-center"
                  type="button"
                >
                  <span class="icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.63471 10.8528H5.61376C5.89671 10.8528 6.08503 10.6642 6.08503 10.3816C6.08503 10.099 5.8964 9.91033 5.61376 9.91033H3.63471C3.16376 9.91033 2.6925 9.81632 2.22123 9.7217C1.9386 9.62769 1.74997 9.53338 1.56165 9.43906C1.37303 9.34506 1.27871 9.06212 1.27871 8.8738C1.27871 8.68517 1.37303 8.49654 1.46734 8.30822C1.9386 7.74295 2.78681 7.36601 3.91765 6.98906C4.01197 6.98906 4.01197 6.89474 4.10628 6.89474C4.38892 6.70612 4.57724 6.42348 4.67155 6.14085C4.67155 5.76359 4.48292 5.48096 4.20029 5.29264C3.54071 4.35012 3.16376 3.50222 3.25776 2.74801C3.35208 2.27706 3.54071 1.90011 3.91765 1.61717C4.57724 1.14591 5.51945 1.14591 6.08534 1.61717C6.46197 1.90011 6.6506 2.27706 6.74492 2.74801C6.83892 3.50191 6.46197 4.35012 5.80239 5.29264C5.61376 5.48127 5.70808 5.7639 5.89671 5.95222C6.08534 6.14085 6.36797 6.04653 6.55629 5.85822C7.49881 4.72706 7.87576 3.59622 7.68713 2.55969C7.59282 1.90011 7.21587 1.24022 6.6506 0.863273C5.70808 0.109378 4.29429 0.109378 3.35208 0.863273C2.78681 1.33453 2.40986 1.90011 2.31555 2.55969C2.22124 3.59622 2.59818 4.72737 3.44639 5.85822C3.54071 5.95222 3.54071 6.04653 3.63502 6.04653C3.63502 6.04653 3.54071 6.04654 3.54071 6.14085C2.31555 6.5178 1.37303 7.08306 0.807759 7.74264C0.524816 8.02559 0.336496 8.49685 0.336496 8.96811C0.336484 9.2241 0.396081 9.47658 0.510565 9.70555C0.625049 9.93452 0.791276 10.1337 0.996078 10.2873C1.27871 10.4759 1.56165 10.6645 1.9386 10.7585C2.50387 10.7585 3.06944 10.8528 3.63471 10.8528Z"
                        fill="#6F00FF"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.9068 10.8521H15.8859C16.5454 10.8521 17.1107 10.7578 17.582 10.5695C17.9592 10.4752 18.2419 10.2866 18.5245 10.0982C18.9014 9.8156 19.1841 9.25002 19.1841 8.77876C19.1841 8.3075 18.9955 7.93055 18.7128 7.5536C18.0532 6.89402 17.111 6.42276 15.8859 6.04581C15.8859 6.04581 15.7916 6.04581 15.7916 5.9515C15.7916 5.9515 15.8859 5.85749 15.8859 5.76287C16.8281 4.63265 17.299 3.40749 17.1104 2.46497C17.0164 1.80538 16.6395 1.14549 16.0739 0.768547C15.1314 0.0146512 13.7179 0.0146512 12.7754 0.768547C12.2101 1.23981 11.8331 1.80538 11.7388 2.46497C11.6445 3.50149 12.0215 4.63265 12.8697 5.76349C13.0583 5.95212 13.3409 5.95212 13.5292 5.8578C13.7179 5.66918 13.8122 5.38654 13.6236 5.19791C12.8697 4.2557 12.587 3.31318 12.6813 2.65359C12.6813 2.18233 12.964 1.80538 13.3409 1.52244C14.0005 1.05149 14.8487 1.05149 15.5086 1.52244C15.8856 1.80538 16.1682 2.18233 16.1682 2.55928C16.2625 3.31318 15.8856 4.16108 15.2257 5.1036C15.037 5.38654 14.7544 5.66918 14.8487 5.95181C14.8487 6.32907 15.1314 6.51739 15.4143 6.7057C15.4143 6.7057 15.5083 6.80002 15.6029 6.80002C16.6395 7.27128 17.3933 7.64823 17.9586 8.2138C18.0529 8.40243 18.1472 8.59075 18.1472 8.77907C18.1472 9.0617 18.0529 9.25033 17.8646 9.34465C17.676 9.43865 17.4874 9.53328 17.2047 9.62728C16.8278 9.81591 16.3565 9.90991 15.8856 9.90991H13.9065C13.6236 9.90991 13.4352 10.0985 13.4352 10.3812C13.4352 10.6638 13.6239 10.8524 13.9065 10.8524L13.9068 10.8521Z"
                        fill="#6F00FF"
                      ></path>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.7809 19.0507H11.6447C12.4929 19.0507 13.2468 18.9564 13.9067 18.6738C14.3776 18.4852 14.7546 18.2968 15.0375 18.0142C15.6028 17.7316 15.8857 17.0717 15.8857 16.4121C15.8857 15.8465 15.6971 15.3753 15.3202 14.9043C14.5663 14.0561 13.3411 13.3962 11.6447 12.8309L11.3621 12.5483L11.6447 12.2654C12.8699 10.6636 13.4351 9.24979 13.2468 7.93032C13.0582 7.08211 12.6815 6.32852 11.9276 5.76263C10.7025 4.91473 8.81743 4.91473 7.68659 5.76263C6.93269 6.32852 6.46143 7.08211 6.36743 7.93032C6.1788 9.24979 6.74437 10.7576 7.87522 12.2657C8.15785 12.5483 8.15785 12.6423 8.15785 12.6423C8.15785 12.6423 7.96922 12.7366 7.87522 12.8309C6.1788 13.3962 4.95364 14.0561 4.19975 14.9043C3.8228 15.2813 3.63417 15.8465 3.63417 16.4121C3.63417 17.0717 3.91711 17.7316 4.38837 18.1085C4.67101 18.3912 5.04796 18.5795 5.51922 18.7681C6.27311 18.9567 7.02701 19.0507 7.7809 19.0507ZM9.75996 5.95126C10.3255 5.95126 10.8908 6.13989 11.3621 6.42252C11.9276 6.79978 12.2103 7.36505 12.3046 8.02463C12.3986 9.06116 11.9273 10.2863 10.9851 11.6058C10.7025 11.8884 10.4195 12.1714 10.5139 12.6423C10.5139 13.0196 10.8911 13.3022 11.1734 13.5848C11.1734 13.5848 11.2677 13.6792 11.3621 13.6792C12.9639 14.1504 14.0007 14.81 14.7546 15.5639C14.8489 15.7525 14.9432 16.1295 14.9432 16.4121C14.9432 16.7891 14.7546 17.166 14.472 17.3543C14.2833 17.5429 13.9067 17.7316 13.5294 17.8256C12.9642 18.0142 12.3046 18.1085 11.6447 18.1085H7.7809C7.12132 18.1085 6.46143 18.0142 5.89616 17.8256C5.51891 17.7316 5.23627 17.5429 4.95364 17.3543C4.67101 17.166 4.48238 16.7891 4.48238 16.4121C4.48238 16.1295 4.57669 15.7525 4.85964 15.5639C5.51922 14.81 6.65006 14.2444 8.25186 13.6792C8.34648 13.6792 8.34648 13.5848 8.44049 13.5848C8.72343 13.3022 9.10037 13.0193 9.10037 12.6423C9.10037 12.2654 8.81743 11.8884 8.5348 11.6058C7.59227 10.3806 7.12132 9.06116 7.21533 8.02463C7.30964 7.36505 7.59258 6.89379 8.15785 6.42283C8.62911 6.13989 9.19438 5.95126 9.75996 5.95126Z"
                        fill="#6F00FF"
                      ></path>
                    </svg>
                  </span>
                  How to create Users & Groups
                </button>
              </div>
            </div>
          </div>
        </div>

        <button class="btn" type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="12"
            viewBox="0 0 19 12"
            fill="none"
          >
            <path
              d="M7.72843 11.8496H11.6783V9.87467H7.72843V11.8496ZM0.816162 0V1.97493H18.5906V0H0.816162ZM3.77856 6.91227H15.6282V4.93734H3.77856V6.91227Z"
              fill="black"
            />
          </svg>
        </button>
        <button class="btn" type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
          >
            <path
              d="M4.00958 11.4149V0H3.081V11.4149L1.24708 9.581L0.590576 10.2375L3.21704 12.864C3.30411 12.951 3.42218 12.9999 3.54529 12.9999C3.6684 12.9999 3.78647 12.951 3.87354 12.864L6.5 10.2375L5.8435 9.581L4.00958 11.4149ZM13 2.7625L10.3735 0.136036C10.2865 0.0489956 10.1684 9.91821e-05 10.0453 9.91821e-05C9.92218 9.91821e-05 9.80411 0.0489956 9.71704 0.136036L7.09058 2.7625L7.74708 3.419L9.58101 1.58507V13H10.5096V1.58507L12.3435 3.419L13 2.7625Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
    </div>
    <!-- User-welcome content end here...-->

    <div class="dashboard-tabs" *ngIf="isShowNavigation">
      <ul class="nav nav-pills" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            [ngClass]="{ active: currentPath === '/dashboard' }"
            routerLink="/dashboard"
            role="button"
          >
            Dashboard
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            [ngClass]="{ active: currentPath.includes('task-board') }"
            routerLink="/dashboard/task-board"
            role="button"
          >
            Tasks
          </a>
        </li>
        <li class="nav-item d-none" role="presentation">
          <a
            routerLink="/dashboard/config"
            class="nav-link"
            [ngClass]="{ active: currentPath.includes('config') }"
            role="button"
          >
            <span>
              <img src="/assets/icons/Dash-Configs.svg" alt="Dash-Configs" />
            </span>
            Configs
          </a>
        </li>

        <li class="nav-item" role="presentation">
          <a
            routerLink="/dashboard/config/workflows"
            [ngClass]="{ active: currentPath.includes('workflows') }"
            role="button"
            class="nav-link"
          >
            Workflow
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a
            routerLink="/dashboard/config/users"
            role="button"
            class="nav-link"
          >
            Users and Groups
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="javascript:void(0);" role="button" class="nav-link">
            Documents
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a href="javascript:void(0);" role="button" class="nav-link">
            Timelines
          </a>
        </li>
      </ul>
      <!-- <div class="ms-auto">
      <button class="btn btn-help" type="button">
        <span>
          <img src="/assets/icons/Dash-Help.svg" alt="Dash-Help" />
        </span>
        Help
      </button>
    </div> -->
    </div>
  </div>
  <router-outlet></router-outlet>
</section>
