import { CommonController } from "../util/common.controller";
import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-tech-comparision",
  styleUrls: ["./_tech-comparision.component.scss"],
  template: `
    <section class="tech-stack">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-11">
            <h4 class="title mb-0">Integrate with Your Existing Tech Stack</h4>
            <p class="description">
              Integrity connects with what you already use.
            </p>
          </div>
          <div class="col-12 col-lg-11">
            <div class="tech-comparison-tab">
              <table class="comparison-table table">
                <thead>
                  <th></th>
                  <th>
                    <img src="/assets/img/logo-dark.svg" alt="logo" />
                  </th>
                  <th>
                    <img src="/assets/img/clickup-logo.svg" alt="logo" />
                  </th>
                  <th>
                    <img src="/assets/img/zoho-logo.svg" alt="logo" />
                  </th>
                  <th>
                    <img src="/assets/img/zapier-logo.svg" alt="logo" />
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h4 class="title">Primary Function</h4>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>Simplifies task Management and team collabration</p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>Simplifies task Management and team collabration</p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>Simplifies task Management and team collabration</p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>Simplifies task Management and team collabration</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 class="title">Core Focus</h4>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Business Operations Where relationships and
                          communication are crucial
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Business Operations Where relationships and
                          communication are crucial
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Business Operations Where relationships and
                          communication are crucial
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Business Operations Where relationships and
                          communication are crucial
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 class="title">Interface</h4>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <div>
                          <p>User-friendly interface</p>
                          <ul class="list-unstyled">
                            <li>
                              <p>-visual workflow builder</p>
                            </li>
                            <li>
                              <p>-task management tools</p>
                            </li>
                            <li>
                              <p>-forms</p>
                            </li>
                            <li>
                              <p>-data storage</p>
                            </li>
                            <li>
                              <p>-integration Capabilities</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <div>
                          <p>User-friendly interface</p>
                          <ul class="list-unstyled">
                            <li>
                              <p>-visual workflow builder</p>
                            </li>
                            <li>
                              <p>-task management tools</p>
                            </li>
                            <li>
                              <p>-forms</p>
                            </li>
                            <li>
                              <p>-data storage</p>
                            </li>
                            <li>
                              <p>-integration Capabilities</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <div>
                          <p>User-friendly interface</p>
                          <ul class="list-unstyled">
                            <li>
                              <p>-visual workflow builder</p>
                            </li>
                            <li>
                              <p>-task management tools</p>
                            </li>
                            <li>
                              <p>-forms</p>
                            </li>
                            <li>
                              <p>-data storage</p>
                            </li>
                            <li>
                              <p>-integration Capabilities</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <div>
                          <p>User-friendly interface</p>
                          <ul class="list-unstyled">
                            <li>
                              <p>-visual workflow builder</p>
                            </li>
                            <li>
                              <p>-task management tools</p>
                            </li>
                            <li>
                              <p>-forms</p>
                            </li>
                            <li>
                              <p>-data storage</p>
                            </li>
                            <li>
                              <p>-integration Capabilities</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 class="title">key Features</h4>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <div>
                          <p>Main features</p>
                          <ul class="list-unstyled">
                            <li>
                              <p>-workflow builder</p>
                            </li>
                            <li>
                              <p>-task management</p>
                            </li>
                            <li>
                              <p>-forms</p>
                            </li>
                            <li>
                              <p>-integration, Customization</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <div>
                          <p>Main features</p>
                          <ul class="list-unstyled">
                            <li>
                              <p>-workflow builder</p>
                            </li>
                            <li>
                              <p>-task management</p>
                            </li>
                            <li>
                              <p>-forms</p>
                            </li>
                            <li>
                              <p>-integration, Customization</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <div>
                          <p>Main features</p>
                          <ul class="list-unstyled">
                            <li>
                              <p>-workflow builder</p>
                            </li>
                            <li>
                              <p>-task management</p>
                            </li>
                            <li>
                              <p>-forms</p>
                            </li>
                            <li>
                              <p>-integration, Customization</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <div>
                          <p>Main features</p>
                          <ul class="list-unstyled">
                            <li>
                              <p>-workflow builder</p>
                            </li>
                            <li>
                              <p>-task management</p>
                            </li>
                            <li>
                              <p>-forms</p>
                            </li>
                            <li>
                              <p>-integration, Customization</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 class="title">Best Suited For</h4>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Businesses that need to improve task management,
                          collabration and automation
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Businesses that need to improve task management,
                          collabration and automation
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Businesses that need to improve task management,
                          collabration and automation
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Businesses that need to improve task management,
                          collabration and automation
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 class="title">Pricing</h4>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Likely Subscription-based, with potential for freemium
                          or trial option
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Likely Subscription-based, with potential for freemium
                          or trial option
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Likely Subscription-based, with potential for freemium
                          or trial option
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Likely Subscription-based, with potential for freemium
                          or trial option
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4 class="title">Integration Capabilities</h4>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Highly integrable, with pre-built integrations and
                          APIs for custom connections
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Highly integrable, with pre-built integrations and
                          APIs for custom connections
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Highly integrable, with pre-built integrations and
                          APIs for custom connections
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-start gap-2">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="18"
                            height="18"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512"
                            xml:space="preserve"
                            class=""
                          >
                            <g>
                              <linearGradient
                                id="a"
                                x1="0"
                                x2="512"
                                y1="256"
                                y2="256"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stop-color="#0caa0c"></stop>
                                <stop offset=".6" stop-color="#4bd14b"></stop>
                                <stop offset="1" stop-color="#60e260"></stop>
                              </linearGradient>
                              <path
                                fill="url(#a)"
                                d="M97.7 503.54c105.53 11.28 211.06 11.28 316.59 0 43.01-4.99 84.26-46.24 89.25-89.25 11.28-105.53 11.28-211.06 0-316.59-4.99-43.01-46.24-84.25-89.25-89.25C308.76-2.83 203.23-2.83 97.7 8.45c-43.01 5-84.25 46.24-89.24 89.25-11.28 105.53-11.28 211.06 0 316.59 4.99 43.01 46.24 84.25 89.25 89.25zm5.93-286.03c16.03-14.38 40.69-13.05 55.07 2.99l58.88 65.63 136.38-144.62c14.78-15.67 39.46-16.4 55.13-1.62s16.39 39.46 1.62 55.13L245.24 370.49a38.984 38.984 0 0 1-28.37 12.24h-.49a39.029 39.029 0 0 1-28.55-12.95l-87.19-97.19c-14.38-16.03-13.05-40.69 2.99-55.07z"
                                opacity="1"
                                data-original="url(#a)"
                                class=""
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <p>
                          Highly integrable, with pre-built integrations and
                          APIs for custom connections
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  `,
  styles: [``],
})
export class TechComparisionComponent {
  constructor() {}
}
