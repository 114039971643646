import { Component } from '@angular/core';
import { CommonController } from '../util/common.controller';

@Component({
  selector: 'tech-stack',
  styleUrls: ['./_tech-stack.component.scss'],
  template: `
	     <section class="tech-stack">
         <div class="container">
           <div class="row justify-content-center">
            <div class="col-12 col-lg-11">
              <h4 class="title mb-0">Integrate with Your Existing Tech Stack</h4>
              <p class="description">
                Connects with what you already use.
              </p>
            </div>
            <div class="col-12 col-lg-11">
              <div class="tech-content">
                <div class="card">
                  <div class="content-img">
                    <img src="/assets/img/slack.svg" alt="" />
                  </div>
                </div>
                <div class="card">
                  <div class="content-img">
                    <img src="/assets/img/jira.svg" alt="" />
                  </div>
                </div>
                <div class="card">
                  <div class="content-img">
                    <img src="/assets/img/microsoft.svg" alt="" />
                  </div>
                </div>
                <div class="card">
                  <div class="content-img">
                    <img src="/assets/img/sap.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
         </div>
       </section>
	`,
  styles: [``]
})
export class TechStackComponent extends CommonController { }
