import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./auth/login/login.component";
import { RegisterComponent } from './auth/register/register.component';
import { ResetComponent } from './auth/reset.component';
import { ConsoleComponent } from './console/console.component';
import { Error404Component } from './error/404.component';
import { AuthGuard } from './guard/auth.guard';
import { ForgotComponent } from './auth/forgot.component';
import { AppListComponent } from "./directives/app-list.component";
import { AboutComponent } from "./additionalPages/about/about.component";
import { DashboardComponent } from "./additionalPages/dashboard/dashboard.component";
import { FeaturesComponent } from "./additionalPages/features/features.component";
import { PricingComponent } from "./additionalPages/pricing/pricing.component";
import { SuccessPageComponent } from "./additionalPages/pricing/statusURL/payment-success.component";
import { CancelPageComponent } from "./additionalPages/pricing/statusURL/payment-cancel.component";
import { UserDashbaordComponent } from "./additionalPages/user-dashbaord/user-dashbaord.component";
import { TaskBoardComponent } from "./additionalPages/task-board/task-board.component";
import { CompanyListComponent } from "./additionalPages/company-list/company-list.component";
import { PublicFormsComponent } from "./additionalPages/public-forms/public-forms.component";
import { RoleGuard } from './guard/role.guard'
import { DemoComponent } from "./auth/demo/demo.component";
import { MyTaskComponent } from "./additionalPages/my-task/my-task.component";
import { ChatComponent } from './chat/chat.component';
import { CreateProjectComponent } from "./project/create-project/create-project.component";
import { ListProjectComponent } from "./project/list-project/list-project.component";
import { HelpPageComponent } from "./additionalPages/help-page/help-page.component";
import { TermsServicesComponent } from "./additionalPages/terms-services/terms-services.component";
import { PrivacypolicyComponent } from "./additionalPages/privacypolicy/privacypolicy.component";

const routes: Routes = [
    { path: '', component: HomeComponent, data: { transparentHeader: true } },
    { path: 'login', component: LoginComponent, },
    { path: 'demo', component: DemoComponent, },
    { path: 'register', component: RegisterComponent, },
    { path: 'forgot', component: ForgotComponent, },
    { path: 'reset/:token', component: ResetComponent, },
    { path: 'about', component: AboutComponent, },
    { path: 'plans', component: PricingComponent, },
    { path: 'success', component: SuccessPageComponent, canActivate: [AuthGuard] },
    { path: 'cancel', component: CancelPageComponent, canActivate: [AuthGuard] },
    { path: 'plans', component: PricingComponent, },
    { path: 'features', component: FeaturesComponent, },
    { path: 'accounts', component: CompanyListComponent, },
    { path: 'public-form/:id', component: PublicFormsComponent },
    { path: 'terms-services', component: TermsServicesComponent, },
    { path: 'privacy-policy', component: PrivacypolicyComponent, },

    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: UserDashbaordComponent },
            { path: 'task-board', component: TaskBoardComponent },
            { path: 'manage/chat', component: ChatComponent },
            { path: 'manage/create-project', component: CreateProjectComponent },
            { path: 'manage/edit-project/:id', component: CreateProjectComponent },
            { path: 'manage/projects', component: ListProjectComponent },
            { path: 'manage/my-task', component: MyTaskComponent },
            { path: 'help', component: HelpPageComponent },
            {
                path: 'config',
                component: ConsoleComponent,
                children: [
                    { path: '', component: AppListComponent },
                    {
                        path: 'forms',
                        loadChildren: () => import('../forms-instance/forms-instance.module').then(m => m.FormsInstanceModule),
                        data: { showNavigation: false }
                    },
                    {
                        path: 'files',
                        loadChildren: () => import('../file/file.module').then(m => m.FileModule),
                        data: { showNavigation: false }
                    },
                    {
                        path: 'data-tables',
                        loadChildren: () => import('../data-table/data-table.module').then(m => m.DataTableModule),
                        data: { showNavigation: false }
                    },
                    {
                        path: 'users',
                        loadChildren: () => import('../user/user.module').then(m => m.UserModule),
                        data: { showNavigation: false }
                    },
                    {
                        path: 'email-templates',
                        loadChildren: () => import('../email-template/email-template.module').then(m => m.EmailTemplateModule),
                        data: { showNavigation: false }
                    },
                    {
                        path: 'workflows',
                        loadChildren: () => import('../workflow/workflow.module').then(m => m.WorkflowModule),
                        data: { showNavigation: false }
                    },
                ]
            },
        ]
    },

    {
        path: '**',
        component: Error404Component
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: true,
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
