import { Component } from '@angular/core';
import { CommonController } from '../util/common.controller';

@Component({
	selector: 'banner',
	styleUrls: ['./_banner.component.scss'],
	template: `
    <section class="home-banner">
        <<div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-11">
              <div class="banner-content">
                <h4 class="title">Streamlined Business Workflows Made Easy</h4>
                <p class="description">
                  Simplify, Integrate, Thrive: Your Journey to Operational Excellence
                </p>
                <a [routerLink]="['login']" class="btn btn-dark" type="button"
                  >Get started it's FREE</a
                >
                <div class="banner-box">
								 <img class="banner-img" src="../../../../assets/img/home-banner.png" alt="home-banner" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
	`,
	styles: [`
		.splash {
			background: url('/assets/images/background-1.jpg');
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			background-position: 50% 50%;
			background-attachment: initial;
			background-repeat: no-repeat;
			background-color: #464950;
			min-height: 100vh;
		}

		@media (max-width: 767px) {
			.splash {
				background-position: 50% 0%;
				background-size: auto;
			}

			.splash .container .row {
				min-height: 1vh !important;
				padding-bottom: 40px !important;
			}
		}
	`]
})
export class HomeBannerComponent extends CommonController { }
