import { Component } from "@angular/core";
import { CommonController } from "../util/common.controller";

@Component({
  selector: "config-feature",
  styleUrls: ["./_config-features.component.scss"],
  template: `
    <section class="featured-config">
      <div class="container-fluid">
        <div class="d-none">
          <h2 class="title">c Components</h2>
          <p class="description">
            FusionFlow Components are purpose-built to cater to specific,
            well-defined needs. Each component serves a distinct, specialized
            function, contributing to the overall efficiency and flexibility of
            your workflows.
          </p>
        </div>

          <!-- featured-config-1 section start here... -->
          <div class="row align-items-center featured-card" id="form-template-feature">
            <div class="col-12 col-lg-6">
              <div class="config-content">
                <div class="config-anchor">
                  <div class="card-img">
                    <img
                      src="/assets/icons/Form-instances-config.svg"
                      alt="Form-instances-config"
                    />
                  </div>
                  <h4 class="mb-0">
                    <a
                      href="/#/#form-template-feature"
                      role="button"
                      class="btn stretched-link"
                    >
                      Form Templates: Simplify Data Collection
                    </a>
                  </h4>
                </div>
                <p class="description">
                Effortlessly elevate your data-gathering processes with FusionFlow's Form Templates component.
                Tailor and deploy customizable form instances seamlessly, optimizing user input collection for
                greater accuracy and workflow efficiency. Ensure that every data point is captured with precision,
                turning complex business needs into streamlined, actionable insights. Perfect for businesses of all
                sizes, FusionFlow's Form Templates provide the backbone of fluid data management, enabling swift
                adaptation to your unique requirements.
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="config-img">
                <img
                  class="w-100"
                  src="/assets/img/Form-Instances.webp"
                  alt="form-instance"
                />
              </div>
            </div>
          </div>
          <!-- featured-config-1 section end here... -->

          <!-- featured-config-2 section start here... -->
          <div class="row align-items-center featured-card" id="file-storage-feature">
            <div class="col-12 col-lg-6">
              <div class="config-img">
                <img
                  class="w-100"
                  src="/assets/img/Form-Instances.webp"
                  alt="form-instance"
                />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="config-content">
                <div class="config-anchor">
                  <div class="card-img">
                    <img
                      src="/assets/icons/File-storage-config.svg"
                      alt="File-storage-config"
                    />
                  </div>
                  <h4 class="mb-0">
                    <a
                      href="/#/#file-storage-feature"
                      role="button"
                      class="btn stretched-link"
                    >
                      File Storage: Secure and Encrypted
                    </a>
                  </h4>
                </div>
                <p class="description">
                  Ensure your digital assets are protected and always within reach with FusionFlow's File Storage
                  component. Experience peace of mind knowing your files are encrypted automatically, fortifying
                  your data against unauthorized access while maintaining seamless accessibility for authorized
                  users. By integrating top-tier encryption and ease of sharing, our File Storage solution delivers
                  the highest standard of data protection, ensuring your critical files remain both secure and accessible.
                </p>
              </div>
            </div>
          </div>
          <!-- featured-config-2 section end here... -->

          <!-- featured-config-3 section start here... -->
          <div class="row align-items-center featured-card" id="data-table-feature">
            <div class="col-12 col-lg-6">
              <div class="config-content">
                <div class="config-anchor">
                  <div class="card-img">
                    <img
                      src="/assets/icons/Data-table-config.svg"
                      alt="Data-Tables-config"
                    />
                  </div>
                  <h4 class="mb-0">
                    <a
                      href="/#/#data-table-feature"
                      role="button"
                      class="btn stretched-link"
                    >
                      Data Tables: Versatile Data Management
                    </a>
                  </h4>
                </div>
                <p class="description">
                  Unlock the full potential of your data management with FusionFlow's Data Tables component. Effortlessly
                  coordinate, store, and manage diverse data sets with precision. Enjoy unparalleled organization and analytical
                  capabilities, allowing you to maintain structured, accessible data for insightful decision-making. This component
                  facilitates seamless integration with other modules, ensuring your data is always ready to fuel your business
                  intelligence efforts amidst an ever-changing digital landscape.
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="config-img">
                <img
                  class="w-100"
                  src="/assets/img/Data-Tables.webp"
                  alt="Data-Tables"
                />
              </div>
            </div>
          </div>
          <!-- featured-config-3 section end here... -->

          <!-- featured-config-4 section start here... -->
          <div class="row align-items-center featured-card" id="users-feature">
            <div class="col-12 col-lg-6">
              <div class="config-img">
                <img
                  class="w-100"
                  src="/assets/img/Users-Groups.webp"
                  alt="Users-Groups"
                />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="config-content">
                <div class="config-anchor">
                  <div class="card-img">
                    <img
                      src="/assets/icons/Users-Groups-config.svg"
                      alt="Users-Groups-config"
                    />
                  </div>
                  <h4 class="mb-0">
                    <a
                      href="/#/#users-feature"
                      role="button"
                      class="btn stretched-link"
                    >
                      Users & Groups: Streamlined Access Control
                    </a>
                  </h4>
                </div>
                <p class="description">
                  Seamlessly coordinate access to critical components of your application with FusionFlow's Users & Groups
                  component. Simplify and secure your access control by assigning user permissions tailored to their
                  organizational roles. This ensures a protected, organized, and efficient management system enabling
                  authorized personnel to execute tasks effectively. Elevate your application's security with the precision
                  of role-based access controls, safeguarding sensitive data while boosting productivity
                </p>
              </div>
            </div>
          </div>
          <!-- featured-config-4 section end here... -->

          <!-- featured-config-5 section start here... -->
          <div class="row align-items-center featured-card" id="email-feature">
            <div class="col-12 col-lg-6">
              <div class="config-content">
                <div class="config-anchor">
                  <div class="card-img">
                    <img
                      src="/assets/icons/Email-templates-config.svg"
                      alt="Email-templates-config"
                    />
                  </div>
                  <h4 class="mb-0">
                    <a
                      href="/#/#email-feature"
                      role="button"
                      class="btn stretched-link"
                    >
                      Email Templates: Personalize Your Messages
                    </a>
                  </h4>
                </div>
                <p class="description">
                  Enhance your brand's communication strategy with FusionFlow's Email Templates component.
                  This powerful tool allows your organization to design personalized, compelling email content
                  that resonates with your audience. Craft messages that are not only tailored but also engaging,
                  providing a unique, unforgettable interaction with every send. Boost your organization's
                  outreach capabilities and connect with users on a more personal level.
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="config-img">
                <img
                  class="w-100"
                  src="/assets/img/Email-Template.webp"
                  alt="email-template"
                />
              </div>
            </div>
          </div>
          <!-- featured-config-5 section end here... -->

          <!-- featured-config-6 section start here... -->
          <div class="row align-items-center featured-card" id="workflow-feature">
            <div class="col-12 col-lg-6">
              <div class="config-img">
                <img class="w-100" src="/assets/img/Workflow.webp" alt="Workflow" />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="config-content">
                <div class="config-anchor">
                  <div class="card-img">
                    <img
                      src="/assets/icons/Workflow-config.svg"
                      alt="Workflow-config"
                    />
                  </div>
                  <h4 class="mb-0">
                    <a href="/#/#workflow-feature" role="button" class="btn stretched-link">
                      Workflows: The Integration Orchestrator
                    </a>
                  </h4>
                </div>
                <p class="description">
                  Maximize operational efficiency and reduce complexity with FusionFlow's Workflows component. This versatile
                  tool orchestrates the seamless flow of information across your organization, defining data logic to automate
                  processes effectively. By turning actions and events into direct, actionable outcomes, it ensures that your
                  business operations are streamlined, errors are reduced, and productivity is greatly enhanced.
                </p>
              </div>
            </div>
          </div>
          <!-- featured-config-6 section end here... -->
        </div>
      </section>
	`,
  styles: [``]
})
export class ConfigFeatureComponent extends CommonController { }
