<section class="login">
  <div class="container-fluid">

    <div class="alert congratulation-alert text-center" *ngIf="isRegister" role="alert">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="70"
          height="70" x="0" y="0" viewBox="0 0 24 24" style="enable-background: new 0 0 512 512" xml:space="preserve"
          class="">
          <g>
            <g fill="#000000" data-name="Flat Color">
              <path
                d="M12 22.75a10.75 10.75 0 0 1 0-21.5 10.53 10.53 0 0 1 4.82 1.15.75.75 0 0 1-.68 1.34 9 9 0 0 0-4.14-1A9.25 9.25 0 1 0 21.25 12a2 2 0 0 0 0-.25.75.75 0 1 1 1.5-.14V12A10.76 10.76 0 0 1 12 22.75z"
                fill="#000000" opacity="1" data-original="#000000"></path>
              <path
                d="M11.82 15.41a.7.7 0 0 1-.52-.22l-4.83-4.74a.75.75 0 0 1 0-1.06.77.77 0 0 1 1.07 0l4.29 4.23 9.65-9.49a.77.77 0 0 1 1.07 0 .75.75 0 0 1 0 1.06l-10.18 10a.74.74 0 0 1-.55.22z"
                fill="#000000" opacity="1" data-original="#000000"></path>
            </g>
          </g>
        </svg>
        <img src="../../../../assets/img/congratulation.gif" class="bg-cong" alt="check-right" />
      </div>

      <h4 class="title">🎉 Congratulations on Joining Fusion Flow!</h4>
      <p>
        Welcome to the FusionFlow family! 🚀
        <br />
        You’re all set to simplify your work and boost your productivity.
        Explore our powerful tools and features tailored to your needs.
        <br />
        Need help? Our support team is just a click away. Let’s make great
        things happen together! 💼✨
      </p>
    </div>

    <div class="login-content-flex mx-auto">
      <div class="pb-3">
        <h4 class="login-title">Sign up to continue!</h4>
        <p class="login-desc mb-0">Enter details to create your account</p>
      </div>
      <div class="card login-card">
        <div class="card-body">
          <form [formGroup]="form">
            <div class="form-group">
              <label for="LoginName" class="form-label">Full name</label>
              <div class="position-relative">
                <text-input field="accountName" placeholder="Enter your name" [form]="form" [errors]="{
                    required: 'Account Name Required',
                    pattern: 'Please enter a valid full name',
                    serverValidation: 'Rejected by server'
                  }"></text-input>
                <span class="icon d-none">
                  <img class="w-100" src="/assets/icons/login-user.svg" alt="login-user" />
                </span>
              </div>
            </div>
            <div class="form-group">
              <label for="LoginEmail" class="form-label">E-mail address</label>
              <div class="position-relative">
                <text-input field="email" placeholder="Enter your email address" [form]="form" [errors]="{
                    required: 'Email Address Required',
                    email: 'Invalid Email Address',
                    serverValidation: 'Rejected by server'
                  }"></text-input>
                <span class="icon d-none">
                  <img class="w-100" src="/assets/icons/login-mail.svg" alt="login-mail" />
                </span>
              </div>
            </div>
            <div class="form-group">
              <label for="LoginCompany" class="form-label">Company name</label>
              <div class="position-relative">
                <text-input field="companyName" placeholder="Enter your company name" [form]="form" [errors]="{
                    required: 'Company name Required',
                    serverValidation: 'Rejected by server'
                  }"></text-input>
                <span class="icon d-none">
                  <img class="w-100" src="/assets/icons/loginCompany.svg" alt="loginCompnay" />
                </span>
              </div>
            </div>
            <!-- <div class="form-group">
              <label for="LoginCompanyLogo" class="form-label"
                >Company logo</label
              >
              <div class="position-relative form-control logo-control">
                <input
                  id="CompanyLogo"
                  class="form-control d-none"
                  (change)="onFileChange($event)"
                  type="file"
                  placeholder="Upload logo"
                />
                <div
                  class="file-preview d-flex align-items-center gap-3 mb-0"
                  *ngIf="fileName"
                >
                  <img
                    class="preview-image"
                    [src]="filePreviewUrl"
                    alt="File Preview"
                  />
                  <span>{{ fileName }}</span>
                  <button class="btn btn-sm ms-auto" (click)="deleteFile()">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <path
                        d="M12.75 7.75L8.25 12.25M8.25 7.75L12.75 12.25M18 10C18 14.1421 14.6421 17.5 10.5 17.5C6.35786 17.5 3 14.1421 3 10C3 5.85786 6.35786 2.5 10.5 2.5C14.6421 2.5 18 5.85786 18 10Z"
                        stroke="#949CA9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <span [class.d-none]="fileName">Upload logo</span>
                <label
                  for="CompanyLogo"
                  class="companyLabel"
                  [class.d-none]="fileName"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M13.5 13L10.5 10L7.5 13"
                      stroke="#949CA9"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 10V16.75"
                      stroke="#949CA9"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.7925 14.7925C17.524 14.3937 18.1018 13.7626 18.4349 12.999C18.7679 12.2353 18.8371 11.3824 18.6316 10.575C18.4261 9.7676 17.9576 9.05162 17.3 8.54007C16.6424 8.02852 15.8331 7.75054 15 7.74999H14.055C13.828 6.87192 13.4048 6.05674 12.8174 5.36573C12.23 4.67473 11.4936 4.12588 10.6635 3.76044C9.83349 3.39501 8.93138 3.22251 8.02506 3.2559C7.11873 3.28929 6.23177 3.52771 5.43085 3.95324C4.62994 4.37876 3.9359 4.98032 3.40094 5.71267C2.86597 6.44503 2.50399 7.28914 2.34221 8.18153C2.18042 9.07393 2.22305 9.99139 2.46688 10.8649C2.71071 11.7385 3.1494 12.5454 3.74997 13.225"
                      stroke="#949CA9"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.5 13L10.5 10L7.5 13"
                      stroke="#949CA9"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </label>
                <span class="icon d-none">
                  <img
                    class="w-100"
                    src="/assets/icons/loginCompanyLogo.svg"
                    alt="loginCompnayLogo"
                  />
                </span>
              </div>
            </div> -->

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="Password" class="form-label">Password (optional)</label>
                  <div class="position-relative">
                    <text-input [type]="passwordVisible ? 'text' : 'password'" field="password"
                      placeholder="Enter Password" [form]="form" [errors]="{
                        pattern:
                          'Password must be 8+ characters with a digit, uppercase, lowercase, and special character. ',
                        serverValidation: 'Rejected by server'
                      }"></text-input>
                    <span class="icon pass-icon" (click)="togglePasswordVisibility('password')">
                      <svg *ngIf="!passwordVisible" xmlns="http://www.w3.org/2000/svg" width="22" height="16"
                        viewBox="0 0 22 16" fill="none">
                        <path
                          d="M1.5 8C1.5 8 4.95455 1 11 1C17.0455 1 20.5 8 20.5 8C20.5 8 17.0455 15 11 15C4.95455 15 1.5 8 1.5 8Z"
                          stroke="#949CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M11 11C12.574 11 13.85 9.65685 13.85 8C13.85 6.34315 12.574 5 11 5C9.42599 5 8.15 6.34315 8.15 8C8.15 9.65685 9.42599 11 11 11Z"
                          stroke="#949CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <svg *ngIf="passwordVisible" xmlns="http://www.w3.org/2000/svg" width="22" height="16"
                        viewBox="0 0 22 16" fill="none">
                        <path
                          d="M1.5 8C1.5 8 4.95455 1 11 1C17.0455 1 20.5 8 20.5 8C20.5 8 17.0455 15 11 15C4.95455 15 1.5 8 1.5 8Z"
                          stroke="#949CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M11 11C12.574 11 13.85 9.65685 13.85 8C13.85 6.34315 12.574 5 11 5C9.42599 5 8.15 6.34315 8.15 8C8.15 9.65685 9.42599 11 11 11Z"
                          stroke="#949CA9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <line x1="1" y1="1" x2="21" y2="15" stroke="#949CA9" stroke-width="2" />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <!-- <div class="col-12">
                <div class="form-group">
                  <label for="ConfirmPassword" class="form-label">Confirm Password</label>
                  <div class="position-relative">
                    <text-input
                      [type]="confirmPasswordVisible ? 'text' : 'password'"
                      field="confirmPassword"
                      placeholder="Confirm Password"
                      [form]="form"
                      [errors]="{
                        required: 'Password Required',
                        isMatching: 'Does not match'
                      }"
                    ></text-input>
                    <span class="icon pass-icon" (click)="togglePasswordVisibility('confirmPassword')">
                      <svg
                        *ngIf="!confirmPasswordVisible"
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="16"
                        viewBox="0 0 22 16"
                        fill="none"
                      >
                        <path
                          d="M1.5 8C1.5 8 4.95455 1 11 1C17.0455 1 20.5 8 20.5 8C20.5 8 17.0455 15 11 15C4.95455 15 1.5 8 1.5 8Z"
                          stroke="#949CA9"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11 11C12.574 11 13.85 9.65685 13.85 8C13.85 6.34315 12.574 5 11 5C9.42599 5 8.15 6.34315 8.15 8C8.15 9.65685 9.42599 11 11 11Z"
                          stroke="#949CA9"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <svg
                        *ngIf="confirmPasswordVisible"
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="16"
                        viewBox="0 0 22 16"
                        fill="none"
                      >
                        <path
                          d="M1.5 8C1.5 8 4.95455 1 11 1C17.0455 1 20.5 8 20.5 8C20.5 8 17.0455 15 11 15C4.95455 15 1.5 8 1.5 8Z"
                          stroke="#949CA9"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11 11C12.574 11 13.85 9.65685 13.85 8C13.85 6.34315 12.574 5 11 5C9.42599 5 8.15 6.34315 8.15 8C8.15 9.65685 9.42599 11 11 11Z"
                          stroke="#949CA9"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <line x1="1" y1="1" x2="21" y2="15" stroke="#949CA9" stroke-width="2"/>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>               -->
            </div>

            <button [disabled]="!canSubmit(form)" (click)="register($event)" class="btn btn-dark mt-3" role="button">
              Signup
            </button>
            <div class="signUp-content">
              <!-- <div class="or-content">
                <p>OR</p>
              </div> -->
              <!-- <button class="btn btn-light google-btn" type="button">
                <span>
                  <img class="w-100" src="/assets/icons/google-icon.svg" alt="google-icon" />
                </span>
                Sign up with Google
              </button> -->
              <p class="d-flex align-items-center justify-content-center">
                Already have an account?
                <a routerLink="/login" role="button" class="btn btn-link">
                  Login
                </a>
              </p>

              <p class="terms-text">
                By clicking the button above, you agree to our
                <a href="javascript:void(0);" role="button" class="btn btn-link">
                  Terms of Service
                </a>
                and
                <a href="javascript:void(0);" role="button" class="btn btn-link">
                  Privacy Policy </a>.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row justify-content-between d-none">
      <div class="col-12 col-lg-5">
        <div class="col-12 alert alert-danger" *ngIf="displayValidationErrors(fieldRejections)"
          [innerHTML]="displayValidationErrors(fieldRejections)"></div>
        <div class="login-content">
          <img class="w-100" src="/assets/img/signup-side.webp" alt="signup-side" />
          <p class="description">
            Simplify, Integrate, Excel: Your Path to Streamlined Operations
          </p>
        </div>
      </div>
      <div class="col-12 col-lg-6"></div>
    </div>
  </div>
</section>