// form-instance

import {
  AuthApi, registerInput, resetInput, authenticateInput, resetContent,
  registerContent, authenticateContent, forgotInput, forgotContent
} from '../api/auth.api';
import { Injectable } from "@angular/core";
import { JwtService } from './jwt.service';
import { apiCallWrapper } from '../api/api.util';
import { NotificationsService } from 'angular2-notifications';
import { Observable } from 'rxjs';
import { IJWTPayload } from '../model/auth.model';
import { publicApi } from '../api/public.api';

@Injectable()
export class PublicService {
  constructor(
    private publicApi: publicApi,
    public notifications: NotificationsService,
  ) { }



  public formInstanceCreate = (opts): Observable<any> => {
    return apiCallWrapper(
      this.publicApi.formInstanceCreate(opts),
      {
        notificationsService: this.notifications,
        action: "Create form instance",
        message: "Creating form instance",
        successMessage: "form instance created successfully"
      }
    );
  }


  public formInstanceUpdate = (opts, id): Observable<any> => {
    return apiCallWrapper(
      this.publicApi.formInstanceUpdate(opts, id),
      {
        notificationsService: this.notifications,
        action: "update form instance",
        message: "Updating form instance",
        successMessage: "form instance updated successfully"
      }
    );
  }
  public formInstanceGet = (id): Observable<any> => {
    return apiCallWrapper(
      this.publicApi.formInstanceGet(id),
      {
        notificationsService: this.notifications,
        action: "Fetch form instance",
        message: "Fetching form instance",
        successMessage: "form instance Fetched successfully"
      }
    );
  }

  public submitPublicFormParticipent = (opts): Observable<any> => {
    return apiCallWrapper(
      this.publicApi.submitPublicFormParticipent(opts),
      {
        notificationsService: this.notifications,
        action: "Submit public form participent",
        message: "Submitting public form participent",
        successMessage: "Public form participation submitted successfully"
      }
    );
  }

  public getPlans = (opts): Observable<any> => {
    return apiCallWrapper(
      this.publicApi.getPlans(opts),
      {
        notificationsService: this.notifications,
        action: "Fetch Plans",
        message: "Fetching Plans",
        successMessage: "Plans fetched successfully"
      }
    );
  }
}