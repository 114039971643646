<div class="modal fade config-add-modal add-user-group show" id="addformModal" tabindex="-1"
  aria-labelledby="addformModalLabel" aria-hidden="true" style="display: block; padding-left: 0px">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-body">
        <!-- <ul *ngIf="!data?.editData" class="nav nav-pills" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button (click)="isGroup = false" class="nav-link" id="pills-Users-tab" data-bs-toggle="pill"
              data-bs-target="#pills-Users" type="button" role="tab" aria-controls="pills-Users" aria-selected="true">
              Users
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button (click)="isGroup = true" class="nav-link active" id="pills-Groups-tab" data-bs-toggle="pill"
              data-bs-target="#pills-Groups" type="button" role="tab" aria-controls="pills-Groups"
              aria-selected="false">
              Groups
            </button>
          </li>
        </ul> -->
        <form *ngIf="isGroup" (submit)="onSubmit()">
          <div class="mb-25">
            <h2 class="title">Add groups</h2>
            <p class="desc mb-0">
              New members will gain access to public Spaces, Docs and
              Dashboards.
            </p>
          </div>
          <!-- <div class="form-group mb-25">
            <label for="GroupIcon" class="form-label icon-label">
              <span class="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 4.75V15.25"
                    stroke="#949CA9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.75 10H15.25"
                    stroke="#949CA9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span class="group-title"> Group icon </span>
            </label>
            <input
              type="file"
              name=""
              id="GroupIcon"
              class="form-control d-none"
            />
          </div> -->
          <div class="form-group mb-25">
            <label for="GroupName" class="form-label">Group name</label>
            <input type="text" class="form-control" [value]="groupName" (change)="handleChangeName($event)"
              id="GroupName" placeholder="Enter Group name" />
          </div>
          <div class="permissions">
            <div class="mb-25">
              <h2 class="title">Permissions for groups</h2>
              <p class="desc mb-0">
                Here, you can set access permissions for groups, allowing them
                to access their tasks.
              </p>
            </div>
            <div class="permission-content">
              <div class="permission-for">
                <h4>Permissions</h4>
                <ul class="list-unstyled">
                  <li *ngFor="let item of permissionData">
                    <div class="form-check">
                      <label class="form-check-label" for="PermissionType">
                        {{ item.name }}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="access-for">
                <h4>Access type</h4>
                <div class="access">
                  <ul class="list-unstyled" *ngFor="let item of permissionData">
                    <li *ngFor="let type of permissionTypeData.types">
                      <div class="form-check">
                        <input class="form-check-input" name="{{ item.id }}" value="{{ type }}" role="switch"
                          (change)="handleChangePermission($event)" [checked]="handleCheckValueExistes(item.id, type)"
                          type="checkbox" id="{{ item.id + type + item }}" />
                        <label class="form-check-label" for="{{ item.id + type + item }}">
                          {{ type }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="permissions">
            <h2 class="title">Permission for group</h2>
            <div class="table-responsive table-permission">
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th>
                      <p>Module</p>
                    </th>
                    <th *ngFor="let item of permissionTypeData.types">
                      <p>{{ item }}</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="form-check form-switch"
                    *ngFor="let item of permissionData"
                  >
                    <td>
                      <label class="form-check-label" for="formInstance">
                        {{ item.name }}
                      </label>
                    </td>
                    <td *ngFor="let type of permissionTypeData.types">
                      <input
                        class="form-check-input"
                        name="{{ item.id }}"
                        value="{{ type }}"
                        role="switch"
                        (change)="handleChangePermission($event)"
                        [checked]="handleCheckValueExistes(item.id, type)"
                        type="checkbox"
                        id="{{ item.id + type + item.name }}"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> -->
          <div class="modal-btns">
            <button type="button" (click)="close()" class="btn btn-secondary">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </form>
        <div *ngIf="!isGroup">
          <app-create-user></app-create-user>
        </div>
      </div>
    </div>
  </div>
</div>