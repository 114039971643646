import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyUserService } from 'src/app/user/service/user.service';
import { FileUploadService } from '../services/fileUpload.service';
import { ChatService } from '../services/chat.service';
import { SocketService } from "../services/socket.service";
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { IChannel, IChannelMessage, ICreateChannel, ICreateMessage } from '../model/chat.model';
import { JwtService } from '../services/jwt.service';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ICreateProject } from '../model/project.model';
import { OffcanvasRef } from 'src/app/ui/service/offcanvas-ref';
import { Projectservice, ProjectUsersService } from '../services/project.service';
import { DialogService } from 'src/app/ui/service/dialog.service';
import { ManageGroupMambersComponent } from './manage-group-mambers/manage-group-mambers.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./_chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  selectedProject: ICreateProject;
  showChannelBody: boolean;
  channelForm: FormGroup;
  users: any = [];
  uploadedImageUrl: string;
  public query: IQueryFilter = new IQueryFilter({
    limit: 10,
  });
  channels: QueryResult<IChannel> = { rows: [], count: 0 };
  currentChannel: IChannel | null;
  message: string;
  channelMessages: QueryResult<IChannelMessage> = { rows: [], count: 0 };
  searchTerms = new Subject<string>();
  searchSubscription: any;
  projectUsers: { id: string, name: string }[];
  currentUser: any;
  activatedAccount: any

  constructor(
    private fb: FormBuilder,
    private myUserService: MyUserService,
    private fileUploadService: FileUploadService,
    private chatService: ChatService,
    public dialog: DialogService,
    private projectService: Projectservice,
    private jwtService: JwtService,
    public projectUsersService: ProjectUsersService,
    private socketService: SocketService
  ) { }

  ngOnInit(): void {
    this.channelForm = this.fb.group({
      groupName: ['', Validators.required],
      description: ['', Validators.required],
      isPublic: [false, Validators.required],
      groupLogo: [''],
      projectId: [undefined],
      users: this.fb.array([])
    });
    this.monitorJwtData();
    this.loadProjects();
    this.search();
    // Listen for new messages
    this.socketService.onMessageReceived()
      .subscribe((message) => {
        this.channelMessages.rows.push(this.convertMessage(message))
        // this.messages.push(message);
      });
  }


  getChannel() {
    this.query = { ...this.query, filter: { projectId: this.selectedProject?.id || '' } }
    this.chatService.list(this.query).subscribe(res => {
      this.channels = res;
      const filteredChannels = res.rows.filter(channel => !channel.isGroup);
      this.projectUsers = this.users.filter(user => {
        return filteredChannels.every(channel => {
          return channel.users.every(channelUser =>
            user.id !== channelUser.accountId
          );
        });
      });
    })
  }
  private monitorJwtData(): void {
    this.jwtService.currentJwtPayload$.subscribe({
      next: (data: any) => {
        if (data) {
          this.activatedAccount = data.account
        }
      },
    });
  }

  private loadProjects(): void {
    this.projectService.getProjects().subscribe(
      (projects: ICreateProject[]) => {
        this.selectedProject = projects.find(item => item.id === this.projectService.getSelectedProject(this.activatedAccount.id)) ?? projects[0];
        if (this.selectedProject) {
          this.projectUsersService.list({ limit: 100, filter: { projectId: this.selectedProject?.id } }).subscribe({
            next: (data) => {
              this.users = data.rows.filter(item => item.account.id != this.activatedAccount.id).map(item => item.account);
              this.getChannel();
            }
          })
        }
      },
      (error) => {
        console.error('Failed to load projects:', error);
      }
    );
  }

  createChatSingleUser() {
    if (this.currentChannel) {
      this.socketService.leaveGroup(this.currentChannel.id)
    }
    this.chatService.singleChat({ projectId: this.selectedProject.id || '', userId: this.currentUser.id, message: this.message }).subscribe(res => {
      this.currentChannel = res.chat;
      this.socketService.joinGroup(res.chat.id)
      if (this.currentChannel) {
        this.getChannelMessage(this.currentChannel);
      }
    });
  }

  setCurrentUser(user) {
    this.channelMessages.rows = [];
    this.currentChannel = null;
    this.currentUser = user;
  }

  get usersFormArray(): FormArray {
    return this.channelForm.get('users') as FormArray;
  }

  onUserToggle(userId: string, event: any): void {
    const usersArray = this.usersFormArray;
    if (event.target.checked) {
      usersArray.push(this.fb.control(userId));
    } else {
      const index = usersArray.controls.findIndex(x => x.value === userId);
      usersArray.removeAt(index);
    }
  }
  getSelectedUserNames(): string[] {
    return this.users
      .filter(user => this.usersFormArray.value.includes(user.id))
      .map(user => user.name);
  }

  onSubmit(): void {
    if (this.channelForm.valid) {
      const payload = this.channelForm.value;

      payload.projectId = this.selectedProject?.id;

      this.chatService.createChannel(payload).subscribe({
        next: (value) => {
          this.showChannelBody = false
          this.getChannel()
        },
        complete: () => { },
        error: (err) => {
          console.log(err)
        },
      })
    }
  }

  async onFileChange(event: any): Promise<void> {
    const fileInput = event.target;
    const file = fileInput.files[0];
    if (file) {
      try {
        const uploadedUrl = await this.uploadImage(file);
        if (uploadedUrl) {
          this.uploadedImageUrl = URL.createObjectURL(file)
          this.channelForm.patchValue({ groupLogo: uploadedUrl });
        }

      } catch (error) {
        console.error('Image upload failed:', error);
      }
    }
  }

  private uploadImage(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      this.fileUploadService.uploadCustomerImage(file, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const s3Prefix = 'https://s3-ap-southeast-2.amazonaws.com/static-dev-fusionflow-com-au/';
          const cfDistribution = 'https://static-dev-fusionflow-com-au/';
          const url = data.Location.replace(s3Prefix, cfDistribution);
          resolve(url);
        }
      });
    });
  }

  addChannel() {
    this.showChannelBody = true;
  }

  getChannelMessage(data: IChannel) {
    if (this.currentChannel) {
      this.socketService.leaveGroup(this.currentChannel.id)
    }
    this.currentChannel = data;
    this.socketService.joinGroup(data.id)
    if (data.id) {
      this.chatService.getAllMessage(data.id).subscribe(res => {
        this.channelMessages = res.result;
      })
    }
  }

  sendMessage() {
    if (!this.message) {
      return
    }
    if (this.currentChannel) {
      const body: ICreateMessage = {
        chatId: this.currentChannel.id,
        message: this.message,
        accountId: this.activatedAccount.id,
        senderName: this.activatedAccount.name,
        createdAt: new Date().toISOString()
      }
      this.socketService.sendMessage(body);
      this.message = '';
      this.channelMessages.rows.push(this.convertMessage(body))

      // this.chatService.sendMessage(body).subscribe(() => {
      //   this.message = '';
      //   if (this.currentChannel) {
      //     this.getChannelMessage(this.currentChannel);
      //   }
      // })
    } else if (this.currentUser) {
      this.createChatSingleUser();
    }
  }
  convertMessage(chat: ICreateMessage) {
    let obj: IChannelMessage = {
      "id": chat.id || '',
      "message": chat.message,
      "replyId": '',
      "createdAt": chat.createdAt,
      "account": {
        "id": chat.accountId,
        "name": chat.senderName,
        "companyLogo": '',
        "companyName": ''
      },
      "reply": ''
    }
    return obj
  }

  getNameLatters(name: string) {
    return name.charAt(0);
  }

  public search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(searchTerm => {
      if (searchTerm && searchTerm.length) {
        this.query.filter.groupName = { $like: '%' + searchTerm + '%' };
      } else {
        delete this.query.filter.groupName;
      }

      this.getChannel();
    });
  }

  onSearch(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    this.searchTerms.next(input.value);
  }

  getGroupName(data: IChannel) {
    if (data.isGroup) {
      return data.groupName;
    } else {
      const user = data.users?.find(item => item.accountId !== this.activatedAccount.id);
      return user?.account.name;
    }
  }


  ngOnDestroy(): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.socketService.onDisconnect()
  }

  checkUserIsHost(chat) {
    let index = chat && chat.users.findIndex(item => item.accountId === this.activatedAccount.id && item.type === 'host');
    if (index > -1) {
      return true
    } else {
      return false
    }
  }

  manageGroup(chat, addMamber) {
    const dialogRef = this.dialog.open(
      ManageGroupMambersComponent,
      { data: { chatData: chat, addMamber: addMamber, users: this.users } },
      "modal-width-xl"
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {

      }
    })
  }

}
