<div class="modal fade config-add-modal add-user-group show" id="addformModal" tabindex="-1"
  aria-labelledby="addformModalLabel" aria-hidden="true" style="display: block; padding-left: 0px">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal fade config-add-modal add-user-group show" id="addformModal" tabindex="-1"
      aria-labelledby="addformModalLabel" aria-hidden="true" style="display: block; padding-left: 0px">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body">
            <div class="max-scroll">
              <!-- <ul *ngIf="!data?.editData" class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button (click)="isGroup = false" class="nav-link active" id="pills-Users-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-Users" type="button" role="tab" aria-controls="pills-Users"
                    aria-selected="true">
                    Users
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button (click)="isGroup = true" class="nav-link" id="pills-Groups-tab" data-bs-toggle="pill"
                    data-bs-target="#pills-Groups" type="button" role="tab" aria-controls="pills-Groups"
                    aria-selected="false">
                    Groups
                  </button>
                </li>
              </ul> -->
              <form [formGroup]="userPermissionForm" (ngSubmit)="onSubmit()">
                <div *ngIf="!isGroup">
                  <div class="mb-25">
                    <h2 class="title">Add users</h2>
                    <p class="desc mb-0">
                      New members will gain access to public Spaces, Docs and
                      Dashboards.
                    </p>
                  </div>

                  <div class="form-group mb-25">
                    <label for="recipient-name" class="form-label">Name</label>
                    <input type="text" placeholder="User Name" class="form-control" formControlName="name"
                      formControlName="name" id="recipient-name" />
                    <div *ngIf="
                  userPermissionForm.get('name')?.invalid &&
                  (userPermissionForm.get('name')?.dirty ||
                    userPermissionForm.get('name')?.touched)
                ">
                      <div class="text-danger" *ngIf="userPermissionForm.get('name')?.errors?.required">
                        Name is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-25">
                    <label for="recipient-name" class="form-label">Email</label>
                    <input type="text" formControlName="email" [disabled]="data?.editData ? true : false"
                      placeholder="Email" class="form-control" id="recipient-name" />
                    <div *ngIf="
                  userPermissionForm.get('email')?.invalid &&
                  (userPermissionForm.get('email')?.dirty ||
                    userPermissionForm.get('email')?.touched)
                ">
                      <div class="text-danger" *ngIf="userPermissionForm.get('email')?.errors?.required">
                        Email is required.
                      </div>
                      <div class="text-danger" *ngIf="userPermissionForm.get('email')?.errors?.email">
                        Invalid email format.
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="recipient-name" class="form-label">Date of Joining</label>
                    <input type="date" formControlName="joiningDate" class="form-control" id="doj-name"
                      [max]="maxDate | date : 'yyyy-MM-dd'" />

                    <div *ngIf="
                  userPermissionForm.get('joiningDate')?.invalid &&
                  (userPermissionForm.get('joiningDate')?.dirty ||
                    userPermissionForm.get('joiningDate')?.touched)
                ">
                      <div class="text-danger" *ngIf="
                    userPermissionForm.get('joiningDate')?.errors?.required
                  ">
                        Date is required.
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="recipient-name" class="form-label">Group</label>
                    <select formControlName="groupId" class="form-control" aria-label="Default select example"
                      (change)="handleChangeGrup($event)">
                      <option value="">Select Group</option>
                      <option *ngFor="let item of groups" [value]="item.groupId" [selected]="
                    this.data?.editData?.groupDetail[0]?.groupId ===
                    item.groupId
                  ">
                        {{ item.name }}
                      </option>
                    </select>
                    <div *ngIf="
                  userPermissionForm.get('groupId')?.invalid &&
                  (userPermissionForm.get('groupId')?.dirty ||
                    userPermissionForm.get('groupId')?.touched)
                ">
                      <div class="text-danger" *ngIf="userPermissionForm.get('groupId')?.errors?.required">
                        Group is required.
                      </div>
                    </div>
                  </div>

                  <div class="permissions">
                    <div class="mb-25">
                      <h2 class="title">Permission for group</h2>
                      <p class="desc mb-0">
                        Here, you can set access permissions for users, allowing them
                        to access their tasks.
                      </p>
                    </div>
                    <div class="permission-content">
                      <div class="permission-for">
                        <h4>Permissions</h4>
                        <ul class="list-unstyled">
                          <li *ngFor="let item of permissionData">
                            <div class="form-check">
                              <label class="form-check-label" for="PermissionType">
                                {{item.name}}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="access-for">
                        <h4>Access type</h4>
                        <div class="access">
                          <ul class="list-unstyled" *ngFor="let item of permissionData">
                            <li *ngFor="let type of permissionTypeData.types">
                              <div class="form-check">
                                <input class="form-check-input" name="{{ item.id }}" value="{{ type }}" role="switch"
                                  (change)="handleChangePermission($event)"
                                  [checked]="handleCheckValueExistes(item.id, type)" type="checkbox"
                                  id="{{ item.id + type + item }}" />
                                <label class="form-check-label" for="{{ item.id + type + item }}">
                                  {{type}}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-btns">
                    <button type="button" (click)="close()" class="btn btn-secondary">
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </form>
              <div *ngIf="isGroup">
                <app-create-group></app-create-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>