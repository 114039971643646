import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  template: `
  <div #modal class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ message }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="confirm()">Confirm</button>
      </div>
    </div>
  </div>
</div>

  ` ,
})
export class ConfirmationModalComponent {
  @Input() title = 'Confirm Action';
  @Input() message = 'Are you sure you want to proceed?';
  @Output() onConfirm = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();
  @ViewChild('modal', { static: false }) modalElement!: ElementRef;

  // Show the modal using native DOM manipulation
  show(): void {
    const modal = this.modalElement.nativeElement;
    modal.style.display = 'block';
    modal.classList.add('show');
    modal.setAttribute('aria-modal', 'true');
    modal.removeAttribute('aria-hidden');
    document.body.classList.add('modal-open');
  }

  // Hide the modal using native DOM manipulation
  hide(): void {
    const modal = this.modalElement.nativeElement;
    modal.style.display = 'none';
    modal.classList.remove('show');
    modal.removeAttribute('aria-modal');
    modal.setAttribute('aria-hidden', 'true');
    document.body.classList.remove('modal-open');
  }

  confirm(): void {
    this.onConfirm.emit();
    this.hide();
  }

  cancel(): void {
    this.onCancel.emit();
    this.hide();
  }
}
