import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-services',
  templateUrl: './terms-services.component.html',
  styleUrls: ['./_terms-services.component.scss']
})
export class TermsServicesComponent {
  constructor(){
    console.log("hello")
  }
}
