import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MyUserService } from 'src/app/user/service/user.service';
import { Projectservice } from '../../services/project.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./_create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {
  projectForm: FormGroup;
  allUsers: any[] = [];
  invitedUsers: any[] = []
  selectedBackground: string = '';
  isSubmitting: boolean = false;
  projectId: string | null = null
  statusEnums = [
    { label: "Primary", class: 'bg-blue-25' },
    { label: "Primary 2", class: 'bg-purple-25' },
    { label: "Secondary", class: 'bg-gray-25' },
    { label: "Warning 1", class: 'bg-orange-25' },
    { label: "Warning 2", class: 'bg-yellow-25' },
    { label: "Danger", class: 'bg-red-25' },
    { label: "Danger 2", class: 'bg-pink-25' },
    { label: "Success", class: 'bg-green-25' },
  ]
  selectedStatus: string | null;

  constructor(
    private fb: FormBuilder,
    private myUserService: MyUserService,
    private projectService: Projectservice,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.myUserService.get('').subscribe({
      next: (data) => {
        console.log("myUserService", data.rows)
        this.allUsers = data?.rows ? data?.rows : [];
      }
    });
    this.projectForm = this.fb.group({
      projectName: ['', Validators.required],
      users: this.fb.array([], Validators.required),
      accessType: ['', Validators.required],
      projectStatus: this.fb.array([], Validators.required),
      startDate: [new Date().toISOString().split('T')[0], Validators.required],
      endDate: [null],
      projectDescription: ['', Validators.required],
    });
  }
  get invitePeople(): FormArray {
    return this.projectForm.get('users') as FormArray;
  }

  handleChangeInviteUser(e) {
    this.invitedUsers = e;
    this.updateUsersFormArray()
  }


  // Update the form array to match the invitedUsers
  updateUsersFormArray() {
    // Clear the form array first
    this.invitePeople.clear();

    // Add each invited user to the form array
    this.invitedUsers.forEach(user => {
      this.invitePeople.push(this.fb.control(user.id));
    });
  }
  addUser(user: string | null): void {
    if (user) {
      this.invitePeople.push(new FormControl(user));
      user = null;
    }
  }

  removeUser(index: number): void {
    this.invitePeople.removeAt(index);
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.projectId = this.route.snapshot.paramMap.get("id");
      this.getProjectDetails(this.projectId)
    }

  }
  getProjectDetails(projectId) {
    this.projectService.getProjectDetails(projectId).subscribe({
      next: (project) => {
        const formattedStartDate = project?.startDate ? new Date(project?.startDate || '').toISOString().split('T')[0] : "";
        const formattedEndDate = project?.endDate ? new Date(project?.endDate || '').toISOString().split('T')[0] : '';

        this.projectForm.patchValue({
          projectName: project?.projectName,
          accessType: project?.accessType,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          projectDescription: project?.projectDescription,
        });
        project.users.forEach(each => {
          if (project.accountId != each.accountId) {
            this.invitedUsers.push(each.account)
          }
        })
        this.updateUsersFormArray()
        project.status && project.status.forEach(status => {
          this.selectedStatus = status.description
          this.addStatus(status.statusName, null);
        });
      },
      error: (err) => console.log("Project Details faild"),
      complete: () => console.log("Project Details Completed"),
    })
  }

  get projectStatus(): FormArray {
    return this.projectForm.get('projectStatus') as FormArray;
  }
  createStatusGroup(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required]
    });
  }


  removeStatus(index: number): void {
    this.projectStatus.removeAt(index);
  }


  onSubmit(): void {
    if (this.projectForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      let service = this.projectId ? this.projectService.updateProject(this.projectId, this.projectForm.value) : this.projectService.createProject(this.projectForm.value)
      service.subscribe({
        next: (data) => {
          if (data) {
            this.projectId = null
            this.router.navigate(['/dashboard']);
          }
        },
        error: (err) => {
          this.isSubmitting = false;
        },
        complete: () => {
          this.isSubmitting = false;
        }
      });
    }
  }


  getUserName(value: string) {
    return this.allUsers.find(data => data.id == value).name;
  }

  selectStatus(status: string) {
    this.selectedStatus = status;
  }

  addStatus(statusName: string, statusInput: HTMLInputElement | null) {
    if (!this.selectedStatus) {
      this.selectedStatus = "bg-blue-25";
    }
    if (this.selectedStatus && statusName) {
      const projectStatusArray = this.projectForm.get('projectStatus') as FormArray;
      const status = {
        name: statusName,
        description: this.selectedStatus,
      };
      projectStatusArray.push(this.fb.group(status));
      this.selectedStatus = null;

      // Clear the input value
      if (statusInput) {
        statusInput.value = '';
      }
    }
  }


  getStatusColor(label: string) {
    return this.statusEnums.find(data => data.label == label)?.class;
  }

  handleCancel() {
    this.router.navigate(["/dashboard"])
  }
}
